





































import { debounce } from "lodash";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class InlineEditDialog extends Vue {
  @Prop() value!: any;
  @Prop() displayValue!: any;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @Prop({ type: Boolean, default: false }) hideReset!: boolean;
  @Prop({ type: Boolean, default: false }) formatPrice!: boolean;
  @Prop({ type: Boolean, default: false }) automaticallySave!: boolean;
  @Prop({ type: Boolean, default: false }) debounce!: boolean;
  @Prop({ type: Function, default: null }) onSave!: () => void;

  originalValue = null as any;
  isOpened = false;
  isValid = false;

  handleCancel() {
    this.isOpened = false;
  }

  handleSave() {
    this.isOpened = false;
    this.save(this.value);
  }

  handleReset() {
    this.$emit("reset");
  }

  save(value: any) {
    this.originalValue = value;
    setTimeout(() => {
      this.isOpened = false;
    });
    this.onSave && this.onSave();
  }

  @Watch("value")
  onValueChange(value: any, oldValue: any) {
    if (value.toString() === oldValue.toString()) return;

    if (this.automaticallySave) {
      if (this.debounce) {
        this.debounceEmitChange(value);
      } else {
        this.save(value);
      }
    }
  }

  debounceEmitChange = debounce((value) => this.save(value), 1500);

  @Watch("isOpened")
  onIsOpenedChanged(value: boolean) {
    if (value) {
      this.originalValue = this.value;
    } else {
      !this.automaticallySave && this.$emit("input", this.originalValue);
    }
  }
}
