

























import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { formatUnixTimestamp } from "@/utils/date";
import ProjectValuationSnapshotService from "@/services/projectValuation/ProjectValuationSnapshotService";
import { maxBy } from "lodash";
import { ProjectValuationSnapshotVersion } from "@/entity/project-valuation/ProjectValuationSnapshot";

@Component
export default class ProjectValuationSnapshotVersionSelect extends Vue {
  @Prop({ type: Number, required: true }) projectId!: number;

  isFetchingVersions = false;
  snapshotVersions: ProjectValuationSnapshotVersion[] = [];
  selectedSnapshotId: number | null = null;

  async getSnapshotVersions(): Promise<void> {
    try {
      this.isFetchingVersions = true;
      this.snapshotVersions = await ProjectValuationSnapshotService.versions(
        this.projectId as number
      );
      this.setLatestVersion();
    } finally {
      this.isFetchingVersions = false;
    }
  }

  get versions() {
    return this.snapshotVersions.map((version) => {
      return {
        ...version,
        createdAt: formatUnixTimestamp(version.createdAt, true),
      };
    });
  }

  @Watch("selectedSnapshotId")
  onSelectedSnapshotIdChange(snapshotId: number): void {
    this.$emit("change", snapshotId);
  }

  onEditClick(item: ProjectValuationSnapshotVersion) {
    this.$emit("edit", item);
  }

  onDeleteClick(item: ProjectValuationSnapshotVersion) {
    this.$emit("delete", item);
  }

  setLatestVersion() {
    this.selectedSnapshotId = maxBy(this.versions, "id")?.id ?? null;
  }

  created() {
    this.getSnapshotVersions();
  }
}
