

















import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import DatePickerWithInput from "@/components/form/input/DatePickerWithInput.vue";

const module = namespace("projectValuationReport");

@Component({
  components: {
    DatePickerWithInput,
  },
})
export default class ProjectValuationPage5Edit extends Vue {
  @module.Getter("getMarketPriceQuote") marketPriceQuote!: string;

  @module.Mutation("setMarketPriceQuote") setMarketPriceQuote!: (
    value: string
  ) => void;
}
