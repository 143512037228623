




















import { Component, Prop, Vue } from "vue-property-decorator";
import ProjectValuationReportDownload from "@/components/project-valuation-report/ProjectValuationReportDownload.vue";
import { Action, Getter, namespace } from "vuex-class";
import { ProjectValuationSnapshot } from "@/entity/project-valuation/ProjectValuationSnapshot";
import ProjectValuationSnapshotService from "@/services/projectValuation/ProjectValuationSnapshotService";
import { ProjectValuationBuyerChart } from "@/entity/project-valuation/ProjectValuationBuyers";

const module = namespace("projectValuationReport");

@Component({
  components: {
    ProjectValuationReportDownload,
  },
})
export default class ProjectValuationReportEditorToolbar extends Vue {
  @Prop({ type: Object, required: true }) snapshot!: ProjectValuationSnapshot;
  @Prop({ type: Array, required: true }) pagesForDownload!: {
    page: number;
    included: boolean;
  }[];

  @Action("saveReportInputs", { namespace: "projectValuationReport" })
  saveReportInputs!: (snapshotId: number) => Promise<void>;
  @Getter("getIsSavingUserInput", { namespace: "projectValuationReport" })
  isSavingUserInput!: boolean;
  @module.Getter("getBuyerTypes")
  buyerTypesFromUserInput!: ProjectValuationBuyerChart[] | null;

  onSaveClicked(): void {
    if (this.buyerTypesFromUserInput) {
      ProjectValuationSnapshotService.updateBuyerStats(
        this.snapshot.projectId,
        this.snapshot.id,
        {
          ...this.snapshot.buyersStats,
          buyerTypes: this.buyerTypesFromUserInput,
        }
      );
    }

    this.saveReportInputs(this.snapshot.projectId);
  }
}
