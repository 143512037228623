













import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ProjectValuationBuyersViewSwitch extends Vue {
  @Prop({ type: Boolean, required: true }) value!: boolean;

  onValueChanged(value: boolean): void {
    this.$emit("input", value);
  }
}
