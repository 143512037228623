



















































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import DatePickerWithInput from "@/components/form/input/DatePickerWithInput.vue";

const module = namespace("projectValuationReport");

@Component({
  components: {
    DatePickerWithInput,
  },
})
export default class ProjectValuationPage17Edit extends Vue {
  @module.Getter("getConclusionQuote")
  conclusionQuote!: string;
  @module.Mutation("setConclusionQuote")
  setConclusionQuote!: (value: string) => void;

  @module.Getter("getConclusionMarketPrice")
  conclusionMarketPrice!: string;
  @module.Mutation("setConclusionMarketPrice")
  setConclusionMarketPrice!: (value: string) => void;

  @module.Getter("getConclusionBuyerDatabase")
  conclusionBuyerDatabase!: string;
  @module.Mutation("setConclusionBuyerDatabase")
  setConclusionBuyerDatabase!: (value: string) => void;

  @module.Getter("getConclusionBtScore")
  conclusionBtScore!: string;
  @module.Mutation("setConclusionBtScore")
  setConclusionBtScore!: (value: string) => void;
}
