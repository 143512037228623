























































































import { Component, Prop, Vue } from "vue-property-decorator";
import ProjectValuationReport from "@/components/project-valuation-report/ProjectValuationReport.vue";
import ProjectValuationEdit from "@/components/project-valuation-report/ProjectValuationEdit.vue";
import { Getter, Mutation } from "vuex-class";
import { ProjectValuationReportUserInput } from "@/store/projectValuationReport/types";
import ProjectValuationReportResponsiveContainer from "@/components/project-valuation-report/ProjectValuationReportResponsiveContainer.vue";
import ProjectValuationReportEditorToolbar from "@/components/project-valuation-report/ProjectValuationReportEditorToolbar.vue";
import { ProjectValuationSnapshot } from "@/entity/project-valuation/ProjectValuationSnapshot";
import Industry, { IndustryType } from "@/entity/Industry";
import AnalyticsService from "@/services/AnalyticsService";
import GlobalService, { FinancialItemType } from "@/services/GlobalService";
import IndustryService from "@/services/IndustryService";
import {
  VCard,
  VCardText,
  VPagination,
  VCheckbox,
  VLayout,
  VCardActions,
  VBtn,
  VRow,
  VCol,
} from "vuetify/lib";
import Contact from "@/entity/Contact";
import { ReferenceItem } from "@/services/types";

@Component({
  components: {
    ProjectValuationReport,
    ProjectValuationEdit,
    ProjectValuationReportEditorToolbar,
    ProjectValuationReportResponsiveContainer,
  },
})
export default class ProjectValuationReportEditor extends Vue {
  @Prop({ type: Object, required: true }) snapshot!: ProjectValuationSnapshot;
  @Getter("getUserInput", { namespace: "projectValuationReport" })
  reportUserInput!: ProjectValuationReportUserInput;
  @Mutation("setUserInputData", { namespace: "projectValuationReport" })
  setUserInputData!: (data: ProjectValuationReportUserInput) => void;
  @Mutation("resetUserInput", { namespace: "projectValuationReport" })
  resetUserInput!: () => void;

  incomeStatementOptions: ReferenceItem[] = [];
  industryOptions: Industry[] = [];
  contactOptions: Contact[] = [];
  activePage = 1;
  pagesForDownload = Array(19)
    .fill(0)
    .map((_, i) => ({ page: i + 1, included: true }));

  get totalVisiblePagesInPagination(): number {
    if (this.$vuetify.breakpoint.xsOnly) return 0;
    return 5;
  }

  get initialAdjustmentsLength(): number {
    return (
      this.snapshot?.projectFinance?.adjustments?.filter((item) => {
        return item.variable.charAt(0) === "+";
      })?.length ?? 0
    );
  }

  handleDownloadCheckbox() {
    this.pagesForDownload = this.pagesForDownload.map((item) => {
      if (item.page === this.activePage) {
        return {
          included: !item.included,
          page: item.page,
        };
      } else {
        return item;
      }
    });
  }

  handleDownloadSelectAll() {
    this.pagesForDownload = this.pagesForDownload.map((item) => ({
      ...item,
      included: true,
    }));
  }

  handleDownloadRemoveAll() {
    this.pagesForDownload = this.pagesForDownload.map((item) => ({
      ...item,
      included: false,
    }));
  }

  async fetchIndustriesAndContacts() {
    this.incomeStatementOptions = await GlobalService.getFinancialItems(
      FinancialItemType.INCOME_STATEMENT
    );
    [this.incomeStatementOptions, this.industryOptions, this.contactOptions] =
      await Promise.all([
        GlobalService.getFinancialItems(FinancialItemType.INCOME_STATEMENT),
        IndustryService.findAllByType(IndustryType.INDUSTRY),
        AnalyticsService.contacts(),
      ]);
  }

  created() {
    this.fetchIndustriesAndContacts();
    if (this.snapshot.userInput !== null) {
      this.setUserInputData(this.snapshot.userInput);
    }
  }

  beforeDestroy() {
    this.resetUserInput();
  }

  getDisplayOfSelectedPagesForDownload(selectedPages: number[]) {
    let value = "";

    let latestNum;
    let numberSequence = [];

    for (let i = 0; i < selectedPages.length; i++) {
      const pageNum = selectedPages[i];
      if (pageNum - 1 === latestNum) {
        numberSequence.push(pageNum);
      } else {
        if (!numberSequence.length) {
          value += `, ${pageNum}`;
        } else {
          value += `-${numberSequence.slice(-1)[0]}, ${pageNum}`;
        }
        numberSequence = [];
      }
      latestNum = pageNum;
    }
    if (numberSequence.length) value += `-${numberSequence.slice(-1)[0]}`;
    value = value.substring(2);

    return value;
  }

  get downloadStateOfActivePage() {
    return (
      this.pagesForDownload.find((item) => item.page === this.activePage)
        ?.included || false
    );
  }
}
