














































import { Component, Prop, Vue } from "vue-property-decorator";
import GraphScore from "@/components/project-valuation-report/GraphScore.vue";
import { ProjectScore } from "@/entity/project-valuation/ProjectValuationScore";

@Component({
  components: {
    GraphScore,
  },
})
export default class FinanceGraphs extends Vue {
  @Prop({ type: Object, required: true }) score!: ProjectScore;
}
