














import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ProjectValuationScoreFinalTable extends Vue {
  @Prop({ type: Number }) rating!: number;

  get ratingReadable(): string {
    return this.rating.toFixed(1);
  }
}
