

































import { Component, Prop, Mixins } from "vue-property-decorator";
import { ProjectValuationReportEndTimelineItem } from "@/store/projectValuationReport/types";
import RulesMixin from "@/mixins/RulesMixin";

@Component
export default class EndTimelineItemEdit extends Mixins(RulesMixin) {
  @Prop({ type: Object, required: true })
  item!: ProjectValuationReportEndTimelineItem;

  onTitleInput(title: string): void {
    this.$emit("input", {
      ...this.item,
      title,
    });
  }

  onTextInput(text: string): void {
    this.$emit("input", {
      ...this.item,
      text,
    });
  }

  onDeleteClick(): void {
    this.$emit("delete");
  }
}
