






















import { Component, Vue, Prop } from "vue-property-decorator";
import Delta from "@/components/project-valuation-report/Delta.vue";
import Graph from "@/components/project-valuation-report/Graph.vue";
import { returnNumberOrZero } from "@/utils/number";

@Component({
  components: {
    Delta,
    Graph,
  },
})
export default class DeltaScore extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: Number, default: null }) projectValue!: number | null;
  @Prop({ type: Number, default: null }) overallValue!: number | null;
  @Prop({ type: Boolean, default: false }) isPercentage!: boolean;
  @Prop({ type: Boolean, default: false }) last!: boolean;

  get safeProjectValue(): number {
    return returnNumberOrZero(this.projectValue);
  }

  get safeOverallValue(): number {
    return returnNumberOrZero(this.overallValue);
  }

  get delta(): string {
    const diff = this.safeProjectValue - this.safeOverallValue;

    if (this.isPercentage) return `${diff} %`;
    return diff.toString();
  }
}
