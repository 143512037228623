















import { Component, Prop, Vue } from "vue-property-decorator";
import ProjectValuationSummaryMarketPrice from "@/components/project-valuation-summary/ProjectValuationSummaryMarketPrice.vue";
import ProjectValuationSummaryScore from "@/components/project-valuation-summary/ProjectValuationSummaryScore.vue";
import ProjectValuationSummaryBuyers from "@/components/project-valuation-summary/ProjectValuationSummaryBuyers.vue";
import { ProjectValuationSnapshot } from "@/entity/project-valuation/ProjectValuationSnapshot";

@Component({
  components: {
    ProjectValuationSummaryMarketPrice,
    ProjectValuationSummaryScore,
    ProjectValuationSummaryBuyers,
  },
})
export default class ProjectValuationSummary extends Vue {
  @Prop({ type: Object, required: true }) snapshot!: ProjectValuationSnapshot;

  get sections() {
    return [
      {
        title: this.$t("projectValuation.steps.marketPrice"),
        component: "ProjectValuationSummaryMarketPrice",
      },
      {
        title: this.$t("projectValuation.steps.score"),
        component: "ProjectValuationSummaryScore",
      },
      {
        title: this.$t("projectValuation.steps.buyers"),
        component: "ProjectValuationSummaryBuyers",
      },
    ];
  }
}
