export const returnNumberOrZero = (number: any): number => {
  if (typeof number === "number") return number;
  return 0;
};

export const inputToNumber = (input: string): number | null => {
  if (input.length === 0) return null;

  const asInt = parseInt(input);

  if (isNaN(asInt)) return null;
  return asInt;
};
