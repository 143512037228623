
















import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

@Component
export default class SelectOptionSelectAll extends Vue {
  @Prop({ type: Array, required: true }) options!: DataTableHeader[];
  @PropSync("selected", { type: Array, required: true })
  selectedLocal!: string[];

  get hasSelectedAll(): boolean {
    return this.options.length === this.selectedLocal.length;
  }

  get hadSelectedSome(): boolean {
    return this.selectedLocal.length > 0 && !this.hasSelectedAll;
  }

  get icon(): string {
    if (this.hasSelectedAll) return "mdi-close-box";
    if (this.hadSelectedSome) return "mdi-minus-box";
    return "mdi-checkbox-blank-outline";
  }

  toggle() {
    this.$nextTick(() => {
      if (this.hasSelectedAll) {
        this.selectedLocal = [];
      } else {
        this.selectedLocal = this.options.map((option) => option.value);
      }
    });
  }
}
