




import { Component, Prop, Vue } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { ApexOptions } from "apexcharts";
import { get } from "lodash";
import { MAIN_MATERIAL_COLOR_LIST } from "@/constants";

@Component({
  components: {
    VueApexCharts,
  },
})
export default class PieChart<T> extends Vue {
  @Prop({ required: true, type: String }) itemText!: string;
  @Prop({ required: true, type: String }) itemValue!: string;
  @Prop({ required: true, type: Array }) data!: T[];

  @Prop({ required: true, type: String }) title!: string;

  longestLabelLength = 40;

  get labels(): any[] {
    return this.data.map((item) => get(item, this.itemText));
  }

  get values(): number[] {
    return this.data.map((item) => get(item, this.itemValue));
  }

  get options(): ApexOptions {
    return {
      colors: MAIN_MATERIAL_COLOR_LIST,
      title: {
        text: this.title,
        align: "center",
      },
      chart: {
        type: "pie",
      },
      labels: this.labels,
      legend: {
        position: "left",
      },
      stroke: {
        show: false,
      },
    };
  }
}
