


















































import { Component, Prop, Vue } from "vue-property-decorator";
import AnalysisTableAdditionalRow from "@/components/project-valuation-market/AnalysisTableAdditionalRow";
import { DataTableHeader } from "vuetify";
import { get } from "lodash";
import { ProjectValuationMarketMultiples } from "@/entity/project-valuation/ProjectValuationMarket";

@Component({
  components: {
    AnalysisTableAdditionalRow,
  },
})
export default class ProjectValuationMarketAnalysisSubTable extends Vue {
  @Prop({ type: Object, required: true })
  flatValues!: ProjectValuationMarketMultiples;
  @Prop({ type: Array, required: true }) headers!: DataTableHeader[];
  @Prop({ type: Array, required: true }) formattedProjects!: any[];
  @Prop({ type: Function, required: true }) removeProject!: (
    projectId: number
  ) => void;
  @Prop({ type: Boolean, default: false }) greenHeader!: boolean;

  additionalRowTypes = ["average", "median", "cutting"];

  get = get;

  get hasActions(): boolean {
    return this.headers.findIndex((header) => header.value === "actions") > -1;
  }
}
