import Axios from "axios";
import { ProjectValuationSnapshot } from "@/entity/project-valuation/ProjectValuationSnapshot";

export default class ProjectValuationReportService {
  static async getWithToken(token: string): Promise<ProjectValuationSnapshot> {
    const { data } = await Axios.get(`/api/v2/valuation/report/token/${token}`);
    return data;
  }

  static async downloadPdf(
    snapshotId: number,
    pagesQuery: string
  ): Promise<Blob> {
    const { data } = await Axios.get(
      `/api/v2/valuation/report/${snapshotId}/downloadPdf?${pagesQuery}`,
      {
        responseType: "blob",
      }
    );
    return data;
  }
}
