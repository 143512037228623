

















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class DeltaLegend extends Vue {
  @Prop({ type: String, required: true }) companyName!: string;

  get readableCompanyName(): string {
    return this.companyName.trim();
  }
}
