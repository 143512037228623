
































import { Component, Vue, Prop } from "vue-property-decorator";
import Rating from "@/components/Rating.vue";
import ScoreTableTotalRow from "@/components/project-valuation-score/ProjectValuationScoreTableTotalRow.vue";
import { ProjectIndustryScore } from "@/entity/project-valuation/ProjectValuationScore";

@Component({
  components: {
    Rating,
    ScoreTableTotalRow,
  },
})
export default class IndustryScoreTable extends Vue {
  @Prop({ type: Object }) industryScore!: ProjectIndustryScore;

  get scores() {
    return [
      {
        name: this.$t("valuation.score.industry.potentialBuyers"),
        score: this.industryScore.potentialBuyers.score,
        overallScore: this.industryScore.potentialBuyers.overallScore,
        rating: this.industryScore.potentialBuyers.rating,
      },
      {
        name: this.$t("valuation.score.industry.purchaseOffers"),
        score: this.industryScore.purchaseOffers.score.toFixed(1),
        overallScore: this.industryScore.purchaseOffers.overallScore.toFixed(1),
        rating: this.industryScore.purchaseOffers.rating,
      },
      {
        name: this.$t("valuation.score.industry.projectDuration"),
        score: this.industryScore.projectDuration.score.toFixed(1),
        overallScore:
          this.industryScore.projectDuration.overallScore.toFixed(1),
        rating: this.industryScore.projectDuration.rating,
      },
    ];
  }
}
