



























import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import NumberOfBuyersGraph from "@/components/project-valuation-report/NumberOfBuyersGraph.vue";
import Footer from "@/components/project-valuation-report/Footer.vue";
import { ProjectValuationBuyerChart } from "@/entity/project-valuation/ProjectValuationBuyers";
import ProjectValuationBuyerTypeTiles from "@/components/project-valuation-buyers/ProjectValuationBuyerTypeTiles.vue";

const module = namespace("projectValuationReport");

@Component({
  components: {
    NumberOfBuyersGraph,
    ProjectValuationBuyerTypeTiles,
    Footer,
  },
})
export default class ProjectValuationExternalReportPage13 extends Vue {
  @module.Getter("getBuyerTypes")
  buyerTypesFromUserInput!: ProjectValuationBuyerChart[] | null;
  @Prop({ type: Number, required: true }) footerPage!: number;
  @Prop({ type: String, required: true }) evaluationOfBuyerDatabase!: string;
}
