














































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ResizableSidebarLayout from "@/layouts/ResizableSidebarLayout.vue";
import ProjectValuationMarketAnalysisSidebar from "@/components/project-valuation-market/ProjectValuationMarketAnalysisSidebar.vue";
import ProjectValuationMarketAnalysisTable from "@/components/project-valuation-market/ProjectValuationMarketAnalysisTable.vue";
import ProjectValuationMarketValuationSidebar from "@/components/project-valuation-market/ProjectValuationMarketValuationSidebar.vue";
import ProjectValuationMarketValuationTable from "@/components/project-valuation-market/ProjectValuationMarketValuationTable.vue";
import ProjectValuationMarketViewSwitch from "@/components/project-valuation-market/ProjectValuationMarketViewSwitch.vue";
import { DataTableHeader } from "vuetify";
import Project from "@/entity/Project";
import { ProjectFinanceDataRow } from "@/entity/ProjectFinance";
import ProjectsService from "@/services/ProjectsService";
import Loader from "@/components/common/Loader.vue";
import ProjectValuationMarketService, {
  FilterData,
  MarketPriceData,
  TableSizes,
  ValuationInputs,
  ValuationOptions,
} from "@/services/projectValuation/ProjectValuationMarketService";
import ProjectValuationMarketFilterResult from "@/entity/project-valuation/ProjectValuationMarket";
import {
  salesOptions,
  transactionMultiplesOptions,
} from "./ProjectValuationMarketFilterOptions";

@Component({
  components: {
    ResizableSidebarLayout,
    ProjectValuationMarketAnalysisTable,
    ProjectValuationMarketAnalysisSidebar,
    ProjectValuationMarketValuationSidebar,
    ProjectValuationMarketValuationTable,
    ProjectValuationMarketViewSwitch,
    Loader,
  },
})
export default class ProjectValuationMarket extends Vue {
  @Prop({ required: false, type: Object }) project!: Project;
  @Prop({ required: true, type: Boolean }) hasEditRights!: boolean;

  projects: ProjectValuationMarketFilterResult[] = [];
  showPriceValuation = false;
  isFetchingProjects = false;
  isInitialLoad = false;
  isSavingData = false;

  baseYear: null | number = null;
  calculatedMultiple: null | string = null;
  selectedTransactionMultiples: string[] = [];
  selectedSales: string[] = [];
  selectedProjects: number[] = [];
  selectedBranches: number[] = [];
  selectedSubBranches: number[] = [];
  tableSizes: TableSizes = {
    company: null,
    sales: null,
    multiples: null,
  };
  valuationInputs: ValuationInputs = {
    minMultipleValue: 0,
    maxMultipleValue: 0,
    averageMin: 0,
    averageMax: 0,
  };
  excludedResults: string[] = [];

  projectFinanceData: ProjectFinanceDataRow[] = [];
  isLoadingFinanceData = false;

  get transactionMultiplesOptions(): DataTableHeader[] {
    return transactionMultiplesOptions();
  }

  get salesOptions(): DataTableHeader[] {
    return salesOptions();
  }

  async fetchProjectFinance(): Promise<void> {
    if (this.project) {
      try {
        this.isLoadingFinanceData = true;
        const response = await ProjectsService.getBalanceSheet(
          this.project.id as number
        );
        this.projectFinanceData = response.incomeStatement?.parsedData ?? [];
      } finally {
        this.isLoadingFinanceData = false;
      }
    }
  }

  async saveMarketData(): Promise<void> {
    try {
      this.isSavingData = true;
      await ProjectValuationMarketService.save(
        this.project.id as number,
        this.marketPriceData
      );
    } finally {
      this.isSavingData = false;
    }
  }

  async fetchProjects(): Promise<ProjectValuationMarketFilterResult[]> {
    try {
      this.isFetchingProjects = true;
      const data = await ProjectValuationMarketService.filterProjects(
        this.selectedProjects,
        this.selectedBranches,
        this.selectedSubBranches
      );
      this.selectedBranches = [];
      this.selectedSubBranches = [];
      this.selectedProjects = data.map((item) => item.project.id!);
      return data;
    } finally {
      this.isFetchingProjects = false;
    }
  }

  async onFilterSubmit(): Promise<void> {
    this.projects = await this.fetchProjects();
  }

  restoreFilterData(data: FilterData): void {
    this.selectedTransactionMultiples = data.transactionMultiples;
    this.selectedSales = data.sales;
    this.selectedSubBranches = data.subBranches;
    this.selectedBranches = data.branches;
    this.selectedProjects = data.projects;
  }

  onRemoveProject(projectId: number): void {
    const index = this.projects.findIndex((p) => p.project.id === projectId);

    if (index > -1) {
      this.projects.splice(index, 1);
    }

    this.selectedProjects = this.selectedProjects.filter(
      (item) => projectId !== item
    );
  }

  async restoreProjects(projectIds?: number[]): Promise<void> {
    const projects = await this.fetchProjects();

    if (!Array.isArray(projectIds)) {
      this.projects = projects;
      return;
    }

    this.projects = projects.filter((project) =>
      projectIds?.includes(project.project.id as number)
    );
  }

  restoreValuationOptions(data: ValuationOptions): void {
    this.baseYear = data.baseYear;
    this.calculatedMultiple = data.calculatedMultiple;
  }

  restoreTableSizes(data: TableSizes): void {
    this.tableSizes.company = data.company;
    this.tableSizes.sales = data.sales;
    this.tableSizes.multiples = data.multiples;
  }

  restoreValuationInputs(valuationInputs: ValuationInputs) {
    this.valuationInputs.minMultipleValue = valuationInputs.minMultipleValue;
    this.valuationInputs.maxMultipleValue = valuationInputs.maxMultipleValue;
    this.valuationInputs.averageMax = valuationInputs.averageMax;
    this.valuationInputs.averageMin = valuationInputs.averageMin;
  }

  // This object is used to store all the data to DB
  get marketPriceData(): MarketPriceData {
    return {
      valuationOptions: {
        baseYear: this.baseYear,
        calculatedMultiple: this.calculatedMultiple,
      },
      valuationInputs: this.valuationInputs,
      filterData: {
        projects: this.selectedProjects,
        branches: this.selectedBranches,
        subBranches: this.selectedSubBranches,
        sales: this.selectedSales,
        transactionMultiples: this.selectedTransactionMultiples,
      },
      showPriceValuation: this.showPriceValuation,
      projectIds: this.projects.map((project) => project.project.id as number),
      excludedValuationResults: this.excludedResults,
      tableSizes: this.tableSizes,
    };
  }

  onTableResized(tableSizes: TableSizes) {
    this.tableSizes = tableSizes;
  }

  onValuationInputsChanged(valuationInputs: ValuationInputs) {
    this.valuationInputs = valuationInputs;
  }

  onExcludedResultsChanged(data: string[]) {
    this.excludedResults = data;
  }

  async initialLoad(): Promise<void> {
    if (this.project) {
      try {
        this.isInitialLoad = true;
        const data = await ProjectValuationMarketService.get(
          this.project.id as number
        );

        if (!data) return;

        if (data.filterData) {
          this.restoreFilterData(data.filterData);
        }

        if (data.valuationOptions) {
          this.restoreValuationOptions(data.valuationOptions);
        }

        if (data.tableSizes) {
          this.restoreTableSizes(data.tableSizes);
        }

        if (data.valuationInputs) {
          this.restoreValuationInputs(data.valuationInputs);
        }

        if (typeof data.showPriceValuation === "boolean") {
          this.showPriceValuation = data.showPriceValuation;
        }

        if (Array.isArray(data.excludedValuationResults)) {
          this.excludedResults = data.excludedValuationResults;
        }

        await this.restoreProjects(data.projectIds);
      } finally {
        this.isInitialLoad = false;
      }
    }
  }

  created() {
    this.initialLoad();
    this.fetchProjectFinance();
  }
}
