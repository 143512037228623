import Axios from "axios";
import Project from "@/entity/Project";
import ProjectValuationMarketFilterResult from "@/entity/project-valuation/ProjectValuationMarket";

export interface MarketPriceData {
  valuationOptions?: ValuationOptions;
  valuationInputs?: ValuationInputs;
  filterData?: FilterData;
  showPriceValuation?: boolean;
  projectIds?: number[];
  excludedValuationResults?: string[];
  tableSizes?: TableSizes;
}

export interface TableSizes {
  company?: number | null;
  sales?: number | null;
  multiples?: number | null;
}

export interface ValuationOptions {
  baseYear: number | null;
  calculatedMultiple: string | null;
}

export interface FilterData {
  projects: number[];
  branches: number[];
  subBranches: number[];
  transactionMultiples: string[];
  sales: string[];
}

export interface ValuationInputs {
  minMultipleValue: number;
  maxMultipleValue: number;
  averageMin: number;
  averageMax: number;
}

export default class ProjectValuationMarketService {
  static async filterProjects(
    projectIds: number[],
    branchIds: number[],
    subBranchIds: number[]
  ): Promise<ProjectValuationMarketFilterResult[]> {
    const { data } = await Axios.get(
      "/api/v2/valuation/market/filterProjects",
      {
        params: {
          projectIds: projectIds.toString(),
          branchIds: branchIds.toString(),
          subBranchIds: subBranchIds.toString(),
        },
      }
    );
    return data;
  }

  static async projectsForValuation(): Promise<Project[]> {
    const { data } = await Axios.get(
      "/api/v2/valuation/market/projectsForValuation"
    );
    return data;
  }

  static async save(
    projectId: number,
    data: Partial<MarketPriceData>
  ): Promise<void> {
    await Axios.post(`/api/v2/valuation/market/${projectId}`, data);
  }

  static async get(projectId: number): Promise<MarketPriceData | null> {
    const { data } = await Axios.get(`/api/v2/valuation/market/${projectId}`);
    return data.data;
  }
}
