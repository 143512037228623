import { DataTableHeader } from "vuetify";
import VueI18n from "@/i18n/i18n";

export const transactionMultiplesOptions = (): DataTableHeader[] => {
  return [
    {
      text: VueI18n.t("netProfit").toString(),
      value: "transactionMultiples.netProfit",
      class: "green-header text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("ebt").toString(),
      value: "transactionMultiples.ebt",
      class: "green-header text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("ebit").toString(),
      value: "transactionMultiples.ebit",
      class: "green-header text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("ebitda").toString(),
      value: "transactionMultiples.ebitda",
      class: "green-header text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("turnover").toString(),
      value: "transactionMultiples.turnover",
      class: "green-header text-right",
      cellClass: "text-right",
    },
  ];
};

export const salesOptions = (): DataTableHeader[] => {
  return [
    {
      text: VueI18n.t("turnover").toString(),
      value: "incomeStatementMultiples.turnover",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("netProfit").toString(),
      value: "incomeStatementMultiples.netProfit",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("ebt").toString(),
      value: "incomeStatementMultiples.ebt",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("ebit").toString(),
      value: "incomeStatementMultiples.ebit",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("ebitda").toString(),
      value: "incomeStatementMultiples.ebitda",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("projectSalesDataForm.salesPrice").toString(),
      value: "projectSales.salesPrice",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("salesPricePublished").toString(),
      value: "project.salesPricePublished",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("projectSalesDataForm.fixedPrice").toString(),
      value: "projectSales.fixedPrice",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("projectSalesDataForm.fixedPriceRatio").toString(),
      value: "projectSales.calculationResults.fixedPriceRatio",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("projectSalesDataForm.earnOutMax").toString(),
      value: "projectSales.earnOutMax",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("projectSalesDataForm.earnOutMaxPercentage").toString(),
      value: "projectSales.calculationResults.earnOutMaxPercentage",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("projectSalesDataForm.ownerLoan").toString(),
      value: "projectSales.ownerLoan",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("projectSalesDataForm.ownerLoanPercentage").toString(),
      value: "projectSales.calculationResults.ownerLoanPercentage",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("projectSalesDataForm.orderDate").toString(),
      value: "projectSales.orderDate",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("projectSalesDataForm.startDate").toString(),
      value: "projectSales.startDate",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("projectSalesDataForm.signing").toString(),
      value: "projectSales.signing",
      class: "text-right",
      cellClass: "text-right",
    },
    {
      text: VueI18n.t("projectSalesDataForm.duration").toString(),
      value: "projectSales.calculationResults.duration",
      class: "text-right",
      cellClass: "text-right",
    },
  ];
};
