














import { Component, Vue, Prop } from "vue-property-decorator";
import CurrencyInput from "@/components/form/input/CurrencyInput.vue";

@Component({
  components: {
    CurrencyInput,
  },
})
export default class PriceValuationInput extends Vue {
  @Prop({ type: [Number, String], required: true }) value!: number | string;
  @Prop({ type: Boolean, default: false }) formatPrice!: boolean;

  get inputComponentType(): string {
    if (this.formatPrice) {
      return "CurrencyInput";
    }

    return "v-text-field";
  }

  onValueChanged(value: string) {
    this.$emit("input", value);
  }
}
