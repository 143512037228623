
























import { Component, Prop, Vue } from "vue-property-decorator";
import Quote from "@/components/project-valuation-report/Quote.vue";
import Footer from "@/components/project-valuation-report/Footer.vue";
import EndTimeline from "@/components/project-valuation-report/EndTimeline.vue";
import { ProjectValuationEndTimeline } from "@/store/projectValuationReport/types";

@Component({
  components: {
    Quote,
    Footer,
    EndTimeline,
  },
})
export default class ProjectValuationExternalReportPage18 extends Vue {
  @Prop({ type: Number, required: true }) footerPage!: number;
  @Prop({ type: Object, required: true })
  timeline!: ProjectValuationEndTimeline;
  @Prop({ type: String, required: true }) endQuote!: string;
}
