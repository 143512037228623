
























import { Component, Prop, Vue } from "vue-property-decorator";
import Quote from "@/components/project-valuation-report/Quote.vue";
import PageIcon from "@/components/project-valuation-report/PageIcon.vue";

@Component({
  components: {
    Quote,
    PageIcon,
  },
})
export default class QuotePageLayout extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: String, required: true }) quote!: string;
  @Prop({ type: String, default: null }) icon!: string | null;
}
