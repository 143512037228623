











































import { Component, Vue, Prop } from "vue-property-decorator";
import Segment from "@/components/project-valuation-report/Segment.vue";
import Footer from "@/components/project-valuation-report/Footer.vue";

@Component({
  components: {
    Segment,
    Footer,
  },
})
export default class ProjectValuationExternalReportPage4 extends Vue {
  @Prop({ type: Number, required: true }) footerPage!: number;
}
