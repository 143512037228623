
















































































































































import { Component, Vue, PropSync, Prop, Ref } from "vue-property-decorator";
import { State } from "vuex-class";
import Industry from "@/entity/Industry";
import { ReferenceItem } from "@/services/types";
import Project from "@/entity/Project";
import { DataTableHeader } from "vuetify";
import SelectOptionSelectAll from "@/components/SelectOptionSelectAll.vue";
import ProjectValuationMarketService from "@/services/projectValuation/ProjectValuationMarketService";

@Component({
  components: {
    SelectOptionSelectAll,
  },
})
export default class ProjectValuationMarketAnalysisSidebar extends Vue {
  @Ref("form") form!: any;

  @Prop({ type: Number, required: false }) projectId!: number;
  @Prop({ type: Boolean, required: true }) isFetchingProjects!: boolean;
  @Prop({ required: true, type: Boolean }) hasEditRights!: boolean;
  @Prop({ required: true, type: Array }) salesOptions!: DataTableHeader[];
  @Prop({ required: true, type: Array })
  transactionMultiplesOptions!: DataTableHeader[];

  @PropSync("selectedTransactionMultiples", { type: Array, required: true })
  selectedTransactionMultiplesLocal!: string[];
  @PropSync("selectedSales", { type: Array, required: true })
  selectedSalesLocal!: string[];
  @PropSync("selectedProjects", { type: Array, required: true })
  selectedProjectsLocal!: number[];
  @PropSync("selectedBranches", { type: Array, required: true })
  selectedBranchesLocal!: number[];
  @PropSync("selectedSubBranches", { type: Array, required: true })
  selectedSubBranchesLocal!: number[];

  @State("industries", { namespace: "selectOptions" })
  industries!: Industry[];
  @State("subIndustries", { namespace: "selectOptions" })
  subIndustries!: ReferenceItem[];

  isLoading = false;
  projectOptions: Project[] = [];

  get isFilterNotEmpty(): boolean {
    return (
      this.selectedProjectsLocal.length > 0 ||
      this.selectedSubBranchesLocal.length > 0 ||
      this.selectedBranchesLocal.length > 0
    );
  }

  get isSubmitDisabled(): boolean {
    return !this.isFilterNotEmpty || this.isLoading;
  }

  resetFilter(): void {
    this.form.reset();
  }

  async fetchProjectOptions(): Promise<void> {
    try {
      this.isLoading = true;
      this.projectOptions =
        await ProjectValuationMarketService.projectsForValuation();
    } finally {
      this.isLoading = false;
    }
  }

  reset(name: string) {
    (this.$refs[name] as any).lazySearch = "";
  }

  created() {
    this.fetchProjectOptions();
  }
}
