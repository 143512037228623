

















import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import DatePickerWithInput from "@/components/form/input/DatePickerWithInput.vue";

const module = namespace("projectValuationReport");

@Component({
  components: {
    DatePickerWithInput,
  },
})
export default class ProjectValuationPage11Edit extends Vue {
  @module.Getter("getMarketValue") marketValue!: string;
  @module.Mutation("setMarketValue") setMarketValue!: (value: string) => void;
}
