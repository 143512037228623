


























































































import { Component, Mixins, Prop, PropSync } from "vue-property-decorator";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import DataTable from "@/components/data-table/DataTable.vue";
import ActionButtonWithTooltip from "@/components/common/ActionButtonWithTooltip.vue";
import ProjectValuationBuyersDataTableToolbar from "@/components/project-valuation-buyers/ProjectValuationBuyersDataTableToolbar.vue";
import { ITableHeader } from "@/components/data-table/types";
import BuyersSelectedActions from "@/components/project-buyers/BuyersSelectedActions.vue";
import { LinkType } from "@/enums";
import Project from "@/entity/Project";
import Contact, { getContactFullName } from "@/entity/Contact";
import { ReferenceItem } from "@/services/types";
import { get, uniqBy } from "lodash";
import Link from "@/components/common/Link.vue";
import contactDetailNavigationTo from "@/utils/contactDetailNavigateTo";
import companyDetailNavigationTo from "@/utils/companyDetailNavigateTo";

@Component({
  components: {
    DataTable,
    ActionButtonWithTooltip,
    BuyersSelectedActions,
    ProjectValuationBuyersDataTableToolbar,
    Link,
  },
})
export default class ProjectValuationBuyersDataTable extends Mixins(
  TranslatedEnumListsMixin
) {
  @PropSync("selectedContacts", { type: Array, required: true })
  selectedContactsLocal!: Contact[];

  @Prop({ required: true, type: Object }) project!: Project;
  @Prop({ required: true, type: Array }) contacts!: Contact[];
  @Prop({ required: true, type: Array }) buyerTypes!: ReferenceItem[];
  @Prop({ type: Boolean, required: true }) hasEditRights!: boolean;
  @Prop({ type: Boolean, required: true }) isLoading!: boolean;
  @Prop({ type: Number, required: true }) totalItems!: number;
  @Prop({ type: Function, required: true }) onOptionsChange!: () => void;
  @Prop({ type: Function, required: true }) openExportDialog!: () => void;

  initialTableOptions: any | null = null;
  contactFullName = getContactFullName;
  contactDetailLink = contactDetailNavigationTo;
  get = get;
  LinkType = LinkType;
  companyDetailLink = companyDetailNavigationTo;

  get headers(): ITableHeader[] {
    const columns: ITableHeader[] = [
      {
        filterType: "text",
        text: this.$tc("name", 1),
        value: "lastName",
        width: "200px",
      },
      {
        filterType: "text",
        text: this.$tc("firstName", 1),
        value: "firstName",
        width: "200px",
      },
      {
        filterType: "text",
        text: this.$tc("city", 1),
        value: "city",
        width: "150px",
      },
      {
        filterType: "text",
        text: this.$tc("email", 1),
        value: "email",
        width: "200px",
      },
      {
        text: this.$tc("companyName", 1),
        value: "company",
        filterType: "text",
        width: "200px",
      },
      {
        sortable: false,
        text: this.$tc("phoneMobile", 1),
        value: "phoneMobile",
        width: "150px",
      },
      {
        text: this.$tc("buyerType", 1),
        value: "buyerType.name",
        filterValue: "buyerTypeId",
        sortable: true,
        options: this.buyerTypes,
        optionText: "name",
        optionValue: "id",
        filterType: "select",
        width: "150px",
      },
    ];

    if (this.hasEditRights) {
      columns.unshift({
        text: this.$tc("select", 1),
        value: "select",
        width: "60px",
        filterType: "selectAll",
      });

      columns.push({
        text: this.$tc("actions"),
        value: "action",
        width: "80px",
      });
    }

    return columns;
  }

  isRowSelected(id: number): boolean {
    return (
      this.selectedContactsLocal.findIndex((contact) => contact.id === id) > -1
    );
  }

  rowSelection(item: Contact): void {
    const existingIndex = this.selectedContactsLocal.findIndex(
      (contact) => contact.id === item.id
    );

    if (existingIndex > -1) {
      this.selectedContactsLocal.splice(existingIndex, 1);
    } else {
      this.selectedContactsLocal.push(item);
    }
  }

  handleSelectAll(): void {
    this.selectedContactsLocal = uniqBy(
      this.contacts.concat(this.selectedContactsLocal),
      (contact) => {
        return contact.id;
      }
    );
  }

  resetSelection() {
    this.selectedContactsLocal = [];
  }

  deleteAll() {
    this.deleteContact(
      this.selectedContactsLocal.map((contact) => contact.id as number)
    );
  }

  deleteContact(contactId: number | number[]): void {
    const idToEmit = Array.isArray(contactId) ? contactId : [contactId];

    this.$emit("delete", idToEmit);
  }
}
