










































import { Component, Prop, Vue } from "vue-property-decorator";
import ValueScore from "@/components/project-valuation-report/ValueScore.vue";
import GraphScore from "@/components/project-valuation-report/GraphScore.vue";
import { ProjectScore } from "@/entity/project-valuation/ProjectValuationScore";

@Component({
  components: {
    ValueScore,
    GraphScore,
  },
})
export default class RiskGraphs extends Vue {
  @Prop({ type: Object, required: true }) score!: ProjectScore;
}
