




































import { Vue, Component, Ref } from "vue-property-decorator";

@Component
export default class ResizableSidebarLayout extends Vue {
  @Ref("drawer") drawer!: Vue;

  maxWidth = 0;
  sidebarWidth = 0;
  minWidth = 380;
  miniVariant = false;
  miniVariantWidth = 56;

  getMaxWidth(): number {
    return window.document.getElementById("main-container")?.clientWidth ?? 0;
  }

  getDefaultWidth(maxWidth: number): number {
    return maxWidth * 0.33;
  }

  get drawerIcon(): string {
    return this.miniVariant ? "mdi-arrow-left" : "mdi-arrow-right";
  }

  get contentRightMargin(): string {
    const actualWidth = this.miniVariant
      ? this.miniVariantWidth
      : this.sidebarWidth;
    return actualWidth + "px";
  }

  toggleMiniDrawer(): void {
    this.miniVariant = !this.miniVariant;
  }

  onWindowResize() {
    this.maxWidth = this.getMaxWidth();

    // Does not allow sidebar to be wider than max width when resizing browser window
    if (this.sidebarWidth > this.maxWidth) {
      this.sidebarWidth = this.maxWidth;
    } else if (this.sidebarWidth < this.minWidth) {
      this.sidebarWidth = this.minWidth;
    }
  }

  setInitialWidths(): void {
    this.maxWidth = this.getMaxWidth();
    const defaultWidth = this.getDefaultWidth(this.maxWidth);

    this.sidebarWidth =
      this.minWidth < defaultWidth ? defaultWidth : this.maxWidth;
  }

  widthToSet(resizeWidth: number): number {
    if (resizeWidth < this.minWidth) {
      return this.minWidth;
    } else if (resizeWidth > this.maxWidth) {
      return this.maxWidth;
    }

    return resizeWidth;
  }

  mounted() {
    this.setInitialWidths();
    window.addEventListener("resize", this.onWindowResize);

    const drawerBorder = this.drawer.$el.querySelector(
      ".v-navigation-drawer__border"
    );

    const resize = (e: MouseEvent) => {
      const width = document.body.offsetWidth - e.clientX;
      const allowedWidth = this.widthToSet(width);
      document.body.style.cursor = "ew-resize";
      (this.drawer.$el as HTMLElement).style.width = `${allowedWidth}px`;
      this.sidebarWidth = allowedWidth;
    };

    /**
     * e is actually of type MouseEvent but when using that type throws some error
     * @param e
     */
    const mouseDown = (e: any) => {
      if (e.offsetX < this.minWidth && !this.miniVariant) {
        (this.drawer.$el as HTMLElement).style.transition = "initial";
        document.addEventListener("mousemove", resize, false);
      }
    };

    if (drawerBorder) {
      drawerBorder.addEventListener("mousedown", mouseDown, false);
    }

    document.addEventListener(
      "mouseup",
      () => {
        document.body.style.cursor = "";
        document.removeEventListener("mousemove", resize, false);
      },
      false
    );
  }

  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  }
}
