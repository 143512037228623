















































import { Component, Prop, Mixins } from "vue-property-decorator";
import ResizableSidebarLayout from "@/layouts/ResizableSidebarLayout.vue";
import ProjectValuationBuyersDataTable from "@/components/project-valuation-buyers/ProjectValuationBuyersDataTable.vue";
import ProjectValuationBuyersForm from "@/components/project-valuation-buyers/ProjectValuationBuyersForm.vue";
import Project from "@/entity/Project";
import { TableQuery } from "@/components/data-table/types";
import { State } from "vuex-class";
import { ReferenceItem } from "@/services/types";
import Contact from "@/entity/Contact";
import ProjectValuationBuyerStatistics from "@/components/project-valuation-buyers/ProjectValuationBuyersStatistics.vue";
import ProjectValuationBuyersViewSwitch from "@/components/project-valuation-buyers/ProjectValuationBuyersViewSwitch.vue";
import ProjectValuationBuyersService from "@/services/projectValuation/ProjectValuationBuyersService";
import { ProjectValuationBuyerStats } from "@/entity/project-valuation/ProjectValuationBuyers";
import DialogMixin from "@/mixins/DialogMixin";
import ExportProjectBuyers from "@/components/project-buyers/ExportProjectBuyers.vue";
import * as XLSX from "xlsx";
import DialogWrapper from "@/components/common/DialogWrapper.vue";

@Component({
  components: {
    ResizableSidebarLayout,
    ProjectValuationBuyersDataTable,
    ProjectValuationBuyersForm,
    ProjectValuationBuyersViewSwitch,
    ProjectValuationBuyerStatistics,
    ExportProjectBuyers,
    DialogWrapper,
  },
})
export default class ProjectValuationBuyers extends Mixins(DialogMixin) {
  @Prop({ required: true, type: Object }) project!: Project;
  @Prop({ required: true, type: Boolean }) hasEditRights!: boolean;

  @State("buyerTypes", { namespace: "selectOptions" })
  buyerTypes!: ReferenceItem[];

  showStatistics = true;
  isFetchingBuyers = false;
  selectedContacts: Contact[] = [];
  contacts: Contact[] = [];
  options: TableQuery = {};
  itemsLength = 0;
  isFetchingBuyerStats = true;
  buyerStats: ProjectValuationBuyerStats = {
    buyerQuality: [],
    numberOfBuyers: [],
    buyerTypes: [],
  };

  async fetchBuyerStats() {
    try {
      this.isFetchingBuyerStats = true;
      this.buyerStats = await ProjectValuationBuyersService.stats(
        this.project.id as number
      );
    } finally {
      this.isFetchingBuyerStats = false;
    }
  }

  onOptionsChange(options: TableQuery) {
    this.options = options;
    this.fetchBuyers();
    this.fetchBuyerStats();
  }

  removeFromSelected(contactIds: number[]) {
    this.selectedContacts = this.selectedContacts.filter(
      (contact) => !contactIds.includes(contact.id as number)
    );
  }

  async fetchBuyers(): Promise<void> {
    try {
      this.isFetchingBuyers = true;
      const response = await ProjectValuationBuyersService.list(
        this.project.id as number,
        this.options
      );

      this.contacts = response.content;
      this.itemsLength = response.totalItems;
    } finally {
      this.isFetchingBuyers = false;
    }
  }

  async deleteContacts(contactIds: number[]): Promise<void> {
    const didConfirm = await this.$confirm(this.$tc("confirmations.delete"));

    if (!didConfirm) {
      return;
    }

    try {
      const response = await ProjectValuationBuyersService.delete(
        this.project.id as number,
        contactIds
      );
      this.removeFromSelected(contactIds);
      this.$snackbarSuccess(
        this.$t("valuation.buyers.delete.success", [response])
      );
    } catch (e) {
      this.$snackbarError(this.$t("valuation.buyers.delete.error"));
    }
  }

  onBuyersAdded() {
    this.fetchBuyers();
    this.fetchBuyerStats();
  }

  async handleDeleteContacts(buyerIds: number[]) {
    await this.deleteContacts(buyerIds);
    await this.fetchBuyers();
  }

  openExportDialog(): void {
    this.openDialog("export");
  }

  async handleExport(filename: string): Promise<any> {
    const response = await ProjectValuationBuyersService.list(
      this.project.id as number,
      { ...this.options, itemsPerPage: 9999999 }
    );
    const finalData = response.content.map((contact) => ({
      [this.$tc("firstName")]: contact?.lastName,
      [this.$tc("name")]: contact?.firstName,
      [this.$tc("email")]: contact?.email,
      [this.$tc("companyName")]: contact.company?.name,
      [this.$tc("phoneMobile")]: contact?.phoneMobile,
      [this.$tc("buyerType")]: contact?.buyerType?.name,
    }));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(finalData);

    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      this.$tc("prospectiveBuyer", 1)
    );

    XLSX.writeFile(workbook, `${filename}.xlsx`, { compression: true });
    this.closeDialog();
  }

  created() {
    this.fetchBuyerStats();
  }
}
