






export default {
  props: {
    page: {
      type: Number,
      required: true,
    },
  },
};
