



















import { Component, Vue, Prop } from "vue-property-decorator";
import Rating from "@/components/project-valuation-report/Rating.vue";
import Graph from "@/components/project-valuation-report/Graph.vue";
import { returnNumberOrZero } from "@/utils/number";

@Component({
  components: {
    Rating,
    Graph,
  },
})
export default class GraphScore extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: Number, default: 0 }) rating!: number | null;
  @Prop({ type: Number, default: 0 }) projectValue!: number | null;
  @Prop({ type: Number, default: 0 }) overallValue!: number | null;
  @Prop({ type: Boolean, default: false }) isPercentage!: boolean;
  @Prop({ type: Number, default: 0 }) toFixed!: number;

  get safeProjectValue(): number {
    return returnNumberOrZero(this.projectValue);
  }

  get safeOverallValue(): number {
    return returnNumberOrZero(this.overallValue);
  }

  get safeRatingValue(): number {
    return returnNumberOrZero(this.rating);
  }
}
