
















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Steps extends Vue {
  @Prop({ type: Number, required: true }) activeStep!: number;
  @Prop({ type: Array, required: true }) steps!: string[];
  @Prop({ type: Boolean, default: false }) vertical!: boolean;

  get lastStepIndex(): number {
    return this.steps.length - 1;
  }

  get componentWrapper(): string {
    if (this.vertical) return "div";
    return "v-stepper-header";
  }
}
