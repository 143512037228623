


























import { Component, Prop, Vue } from "vue-property-decorator";
import ProjectValuationReportEditor from "@/components/project-valuation-report/ProjectValuationReportEditor.vue";
import ProjectValuationSnapshotToolbar from "@/components/project-valuation-snapshot/ProjectValuationSnapshotToolbar.vue";
import ProjectValuationSnapshotService from "@/services/projectValuation/ProjectValuationSnapshotService";
import ProjectValuationSummary from "@/components/project-valuation-summary/ProjectValuationSummary.vue";
import Loader from "@/components/common/Loader.vue";
import { ProjectValuationSnapshot as ProjectValuationSnapshotDTO } from "@/entity/project-valuation/ProjectValuationSnapshot";

@Component({
  components: {
    ProjectValuationSummary,
    ProjectValuationSnapshotToolbar,
    ProjectValuationReportEditor,
    Loader,
  },
})
export default class ProjectValuationSnapshot extends Vue {
  @Prop({ required: true, type: Number }) projectId!: number;

  isFetchingSnapshot = false;
  isReportEditorOpened = false;
  snapshot: null | ProjectValuationSnapshotDTO = null;

  get canOpenEditor(): boolean {
    return this.snapshot !== null;
  }

  async fetchSnapshot(snapshotId: number): Promise<void> {
    try {
      this.isFetchingSnapshot = true;
      this.snapshot = await ProjectValuationSnapshotService.get(
        this.projectId,
        snapshotId
      );
    } finally {
      this.isFetchingSnapshot = false;
    }
  }

  async onSnapshotVersionSelected(snapshotId: number) {
    await this.fetchSnapshot(snapshotId);
  }

  onOpenReportEditorClicked() {
    if (!this.snapshot) return;
    this.isReportEditorOpened = true;
  }
}
