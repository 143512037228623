



































import { Component, Vue } from "vue-property-decorator";

@Component
export default class ProjectValuationExternalReportPage9 extends Vue {}
