















































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import DatePickerWithInput from "@/components/form/input/DatePickerWithInput.vue";
import { formatTimestamp } from "@/utils/date";

const module = namespace("projectValuationReport");

@Component({
  components: {
    DatePickerWithInput,
  },
})
export default class ProjectValuationPage2Edit extends Vue {
  @module.Getter("getCompanyNumber") companyNumber!: string;
  @module.Getter("getClient") client!: string;
  @module.Getter("getCreationDate") creationDate!: string;
  @module.Getter("getValuationDate") valuationDate!: string;

  @module.Mutation("setCompanyNumber") setCompanyNumber!: (
    value: string
  ) => void;

  @module.Mutation("setClient") setClient!: (value: string) => void;
  @module.Mutation("setValuationDate") setValuationDate!: (
    value: string
  ) => void;
  @module.Mutation("setCreationDate") setCreationDate!: (value: string) => void;

  formatTimestamp(value: string | null): string {
    if (value === null || value.length === 0) return "";
    return formatTimestamp(value);
  }
}
