






import { Component, Vue } from "vue-property-decorator";

@Component
export default class ProjectValuationReportResponsiveContainer extends Vue {
  mounted(): void {
    this.$nextTick(() => {
      this.onResize();
      window.addEventListener("resize", this.onResize);
    });
  }

  onResize(): void {
    const preview = document.getElementsByClassName(
      "preview-content"
    )[0] as HTMLElement;

    const previewContainer = preview.parentElement;

    if (!previewContainer) return;

    const parentWidth = previewContainer.clientWidth;
    let scaleToFit = (parentWidth - 24) / preview.clientWidth;

    if (scaleToFit > 1) {
      scaleToFit = 1;
    }

    preview.style.transformOrigin = "0 0";
    preview.style.transform = `scale(${scaleToFit})`;
  }

  beforeDestroy(): void {
    window.removeEventListener("resize", this.onResize);
  }
}
