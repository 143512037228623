










































import { Component, Vue, Prop } from "vue-property-decorator";
import NumberOfBuyersBarChart from "@/components/charts/NumberOfBuyersBarChart.vue";
import PieChart from "@/components/charts/PieChart.vue";
import { ProjectValuationSnapshot } from "@/entity/project-valuation/ProjectValuationSnapshot";
import ProjectValuationSummaryBuyersDataTable from "@/components/project-valuation-summary/ProjectValuationSummaryBuyersDataTable.vue";
import { ProjectValuationSnapshotBuyers } from "@/entity/project-valuation/ProjectValuationBuyers";
import { TableQuery } from "@/components/data-table/types";
import ProjectValuationSnapshotService from "@/services/projectValuation/ProjectValuationSnapshotService";
import ProjectValuationSummaryBuyersFilter from "@/components/project-valuation-summary/ProjectValuationSummaryBuyersFilter.vue";

@Component({
  components: {
    NumberOfBuyersBarChart,
    PieChart,
    ProjectValuationSummaryBuyersDataTable,
    ProjectValuationSummaryBuyersFilter,
  },
})
export default class ProjectValuationSummaryBuyers extends Vue {
  @Prop({ type: Object, required: true }) snapshot!: ProjectValuationSnapshot;

  buyerContacts: ProjectValuationSnapshotBuyers[] = [];
  itemsLength = 0;
  isFetchingBuyers = false;
  options: TableQuery = {};

  get dataTableName(): string {
    return `valuationSummaryBuyers.${this.snapshot.id}`;
  }

  onOptionsChange(options: TableQuery): void {
    this.options = options;
    this.fetchBuyers();
  }

  async fetchBuyers(): Promise<void> {
    try {
      this.isFetchingBuyers = true;
      const response = await ProjectValuationSnapshotService.listBuyers(
        this.snapshot.projectId,
        this.snapshot.id,
        this.options
      );
      this.buyerContacts = response.content;
      this.itemsLength = response.totalItems;
    } finally {
      this.isFetchingBuyers = false;
    }
  }
}
