


















import { Component, Prop, Vue } from "vue-property-decorator";
import QuotePageLayout from "@/components/project-valuation-report/QuotePageLayout.vue";

@Component({
  components: {
    QuotePageLayout,
  },
})
export default class ProjectValuationExternalReportPage12 extends Vue {
  @Prop({ type: String, required: true }) buyerDatabaseQuote!: string;
  @Prop({ type: String, required: true }) chapterNumber!: number;
}
