




























import { Component, Prop, Vue } from "vue-property-decorator";
import Footer from "@/components/project-valuation-report/Footer.vue";
import MarketPriceValuationTable from "@/components/project-valuation-report/MarketPriceValuationTable.vue";
import { ProjectFinanceDataRow } from "@/entity/ProjectFinance";
import { MarketPriceData } from "@/services/projectValuation/ProjectValuationMarketService";

@Component({
  components: {
    MarketPriceValuationTable,
    Footer,
  },
})
export default class ProjectValuationExternalReportPage11 extends Vue {
  @Prop({ type: Number, required: true }) footerPage!: number;
  @Prop({ type: String, required: true }) companyName!: string;
  @Prop({ type: String, required: true }) marketValue!: string;
  @Prop({ type: Object, required: true }) marketPrice!: MarketPriceData;
  @Prop({ type: String, required: true }) branchNames!: string;
  @Prop({ type: String, required: true }) subBranchNames!: string;
  @Prop({ type: Array, required: true })
  projectFinance!: ProjectFinanceDataRow[];
}
