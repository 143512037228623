import { render, staticRenderFns } from "./NumberOfBuyersGraph.vue?vue&type=template&id=2ccebe76&scoped=true&"
import script from "./NumberOfBuyersGraph.vue?vue&type=script&lang=ts&"
export * from "./NumberOfBuyersGraph.vue?vue&type=script&lang=ts&"
import style0 from "./NumberOfBuyersGraph.vue?vue&type=style&index=0&id=2ccebe76&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ccebe76",
  null
  
)

export default component.exports