



























































import { Component, Vue, Prop } from "vue-property-decorator";
import Quote from "@/components/project-valuation-report/Quote.vue";
import PageIcon from "@/components/project-valuation-report/PageIcon.vue";
import SmallSegment from "@/components/project-valuation-report/SmallSegment.vue";
import Footer from "@/components/project-valuation-report/Footer.vue";

@Component({
  components: {
    Quote,
    PageIcon,
    SmallSegment,
    Footer,
  },
})
export default class ProjectValuationExternalReportPage17 extends Vue {
  @Prop({ type: String, required: true }) conclusionQuote!: string;
  @Prop({ type: String, required: true }) conclusionMarketPrice!: string;
  @Prop({ type: String, required: true }) conclusionBuyerDatabase!: string;
  @Prop({ type: String, required: true }) conclusionBtScore!: string;
  @Prop({ type: Number, required: true }) footerPage!: string;
}
