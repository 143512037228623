
































import { Component, Prop, Vue } from "vue-property-decorator";
import Project from "@/entity/Project";
import { State } from "vuex-class";
import { ReferenceItem } from "@/services/types";
import ProjectValuationBuyersFormFilter from "@/components/project-valuation-buyers/ProjectValuationBuyersFormFilter.vue";
import { DEFAULT_MONEY_RANGE_VALUES } from "@/constants";
import ProjectValuationBuyersService from "@/services/projectValuation/ProjectValuationBuyersService";
import {
  ProjectValuationBuyersFilter,
  ProjectValuationBuyersFilterInput,
} from "@/entity/project-valuation/ProjectValuationBuyers";
import { getPriceRangeForSlider } from "@/utils/getPriceRangeForSlider";

@Component({
  components: {
    ProjectValuationBuyersFormFilter,
  },
})
export default class ProjectValuationBuyersForm extends Vue {
  @Prop({ required: true, type: Object }) project!: Project;

  @State("regions", { namespace: "selectOptions" }) regions!: ReferenceItem[];
  @State("industries", { namespace: "selectOptions" })
  industries!: ReferenceItem[];
  @State("subIndustries", { namespace: "selectOptions" })
  subIndustries!: ReferenceItem[];

  steps = DEFAULT_MONEY_RANGE_VALUES;
  isSubmitting = false;
  isFetchingDefaultValues = false;
  defaultFilterValues: ProjectValuationBuyersFilter | null = null;

  selectedIndustries: number[] = [];
  selectedSubIndustries: number[] = [];
  selectedRegions: number[] = [];
  selectedRange: [number, number] = [0, 0];

  get filterInput(): ProjectValuationBuyersFilterInput {
    return {
      salesPricePublishedMin: this.steps[this.selectedRange[0]],
      salesPricePublishedMax: this.steps[this.selectedRange[1]],
      branchIds: this.selectedIndustries,
      subbranchIds: this.selectedSubIndustries,
      regionIds: this.selectedRegions,
    };
  }

  async fetchDefaultFilterValues(): Promise<void> {
    try {
      this.isFetchingDefaultValues = true;
      this.defaultFilterValues = await ProjectValuationBuyersService.filter(
        this.project.id as number
      );
    } finally {
      this.isFetchingDefaultValues = false;
    }
  }

  setDefaultValues() {
    this.selectedRegions = this.defaultFilterValues?.regionIds ?? [];
    this.selectedIndustries = this.defaultFilterValues?.branchIds ?? [];
    this.selectedSubIndustries = this.defaultFilterValues?.subbranchIds ?? [];
    this.selectedRange = getPriceRangeForSlider(
      this.defaultFilterValues?.salesPricePublishedMin ?? null,
      this.defaultFilterValues?.salesPricePublishedMax ?? null
    );
  }

  async handleSubmit(): Promise<void> {
    await this.addRelevantBuyers();
    this.$emit("buyers-added");
  }

  async addRelevantBuyers(): Promise<void> {
    try {
      this.isSubmitting = true;
      await ProjectValuationBuyersService.add(this.project.id as number, {
        ...this.filterInput,
        regionIds: this.filterInput.regionIds?.includes(9)
          ? this.regions.map((item) => item.id)
          : this.filterInput.regionIds,
      });
      this.$snackbarSuccess(this.$t("valuation.buyers.add.success"));
    } catch (e) {
      this.$snackbarError(this.$t("valuation.buyers.add.error"));
    } finally {
      this.isSubmitting = false;
    }
  }

  async created() {
    await this.fetchDefaultFilterValues();
    this.setDefaultValues();
  }
}
