




































import { Vue, Component, Prop } from "vue-property-decorator";
import ProjectFinanceTableRow from "@/components/project-finance-table/ProjectFinanceTableRow.vue";
import { ProjectFinanceDataRow } from "@/entity/ProjectFinance";
import { alphabet } from "@/constants";

@Component({
  components: {
    ProjectFinanceTableRow,
  },
})
export default class ProjectFinanceTable extends Vue {
  @Prop({ required: true, type: Array }) value!: ProjectFinanceDataRow[];
  @Prop({ default: "", type: String }) title!: string;
  @Prop({ default: () => [], type: Array }) boldRows!: string[];
  @Prop({ default: () => [], type: Array }) italicRows!: string[];
  @Prop({ default: () => [], type: Array }) offsetRows!: string[];
  @Prop({ default: () => [], type: Array }) percentageRows!: string[];
  @Prop({ type: Number, default: null }) yearLimit!: number | null;
  @Prop({ type: Boolean, default: false }) showAdjustmentLabel!: boolean;

  get allYears(): string[] {
    const years: Set<string> = new Set();

    this.value?.forEach((row) => {
      if (Array.isArray(row.data)) {
        row.data.forEach((cell: any) => {
          years.add(cell.year);
        });
      }
    });

    return [...years].sort();
  }

  get yearsToUse(): string[] {
    if (this.yearLimit === null) return this.allYears;
    return this.allYears.slice(this.yearLimit * -1);
  }

  get adjustmentLabels() {
    let index = 0;

    return this.value.reduce((acc, val) => {
      if (val.variable.indexOf("+") === 0) {
        const letter = `${alphabet[index]})`;
        index++;
        return {
          ...acc,
          [val.variable]: letter,
        };
      }
      return acc;
    }, {});
  }

  isRowBold(variable: string): boolean {
    return this.boldRows.includes(variable);
  }

  isRowOffset(variable: string): boolean {
    return this.offsetRows.includes(variable);
  }

  isRowPercentage(variable: string): boolean {
    return this.percentageRows.includes(variable);
  }

  isRowItalic(variable: string): boolean {
    return this.italicRows.includes(variable);
  }

  get hasData(): boolean {
    return this.value.length > 0;
  }
}
