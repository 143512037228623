

















import { Component, Vue, Prop } from "vue-property-decorator";
import { ObservationRow } from "@/store/projectValuationReport/types";

@Component
export default class ObservationsTable extends Vue {
  @Prop({ type: Array, required: true }) value!: ObservationRow[];
}
