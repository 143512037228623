



























import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Segment extends Vue {
  @Prop({ required: true, type: String }) title!: string;
  @Prop({ required: true, type: String }) subTitle!: string;
  @Prop({ required: true, type: String }) text!: string;
  @Prop({ required: true, type: String }) image!: string;
}
