



















import { Component, Prop, Vue } from "vue-property-decorator";
import { ProjectValuationBuyersFilterSnapshot } from "@/entity/project-valuation/ProjectValuationBuyers";

@Component
export default class ProjectValuationSummaryBuyersFilter extends Vue {
  @Prop({ type: Object, required: true })
  buyersFilter!: ProjectValuationBuyersFilterSnapshot;

  get branchNames(): string {
    return this.buyersFilter.branchNames?.join(", ") ?? "";
  }

  get regionNames(): string {
    return this.buyersFilter.regionNames?.join(", ") ?? "";
  }

  get salesPrice(): string {
    return `${this.buyersFilter.salesPricePublishedMin ?? ""} - ${
      this.buyersFilter.salesPricePublishedMax ?? ""
    }`;
  }
}
