























































import { Component, Vue, Prop } from "vue-property-decorator";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import IndustryScoreTable from "@/components/project-valuation-score/ProjectValuationScoreIndustryTable.vue";
import RiskScoreTable from "@/components/project-valuation-score/ProjectValuationScoreRiskTable.vue";
import StructureScoreTable from "@/components/project-valuation-score/ProjectValuationScoreStructure.vue";
import FinancialViabilityScoreTable from "@/components/project-valuation-score/ProjectValuationScoreFinancialViabilityTable.vue";
import FinalScoreTable from "@/components/project-valuation-score/ProjectValuationScoreFinalTable.vue";
import { ProjectValuationSnapshot } from "@/entity/project-valuation/ProjectValuationSnapshot";

@Component({
  components: {
    SectionTitle,
    IndustryScoreTable,
    RiskScoreTable,
    StructureScoreTable,
    FinancialViabilityScoreTable,
    FinalScoreTable,
  },
})
export default class ProjectValuationSummaryScore extends Vue {
  @Prop({ type: Object, required: true }) snapshot!: ProjectValuationSnapshot;
}
