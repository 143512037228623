







import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class FinalScore extends Vue {
  @Prop({ type: Number, required: true }) value!: number;

  get formattedValue(): string {
    return this.value.toFixed(1);
  }
}
