


































import { Component, Prop, Vue } from "vue-property-decorator";
import { ProjectValuationEndTimeline } from "@/store/projectValuationReport/types";

@Component
export default class EndTimeline extends Vue {
  @Prop({ type: Object, required: true })
  timeline!: ProjectValuationEndTimeline;

  get itemWidth(): number {
    if (this.timeline.items.length === 0) return 100;
    return 100 / this.timeline.items.length;
  }
}
