



























import { Component, Vue, Prop } from "vue-property-decorator";
import {
  Page2,
  Page3,
  Page5,
  Page7,
  Page9,
  Page10,
  Page11,
  Page12,
  Page13,
  Page14,
  Page15,
  Page17,
  Page18,
} from "@/components/project-valuation-report/editPages";
import ProjectValuationSnapshot from "../project-valuation-snapshot/ProjectValuationSnapshot.vue";
import Contact from "@/entity/Contact";
import Industry from "@/entity/Industry";
import { ReferenceItem } from "@/services/types";

@Component({
  components: {
    Page2,
    Page3,
    Page5,
    Page7,
    Page9,
    Page10,
    Page11,
    Page12,
    Page13,
    Page14,
    Page15,
    Page17,
    Page18,
  },
})
export default class ProjectValuationEdit extends Vue {
  @Prop({ type: Number, required: true }) page!: number;
  @Prop({ type: Number, required: true }) initialAdjustmentsLength!: number;
  @Prop({ type: Object, required: true }) snapshot!: ProjectValuationSnapshot;
  @Prop({ type: Array, required: true }) industryOptions!: Industry[];
  @Prop({ type: Array, required: true }) contactOptions!: Contact[];
  @Prop({ type: Array, required: true })
  incomeStatementOptions!: ReferenceItem[];
}
