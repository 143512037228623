

























































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import DatePickerWithInput from "@/components/form/input/DatePickerWithInput.vue";
import {
  MutationStringByIndexPayload,
  ObservationRow,
} from "@/store/projectValuationReport/types";

const module = namespace("projectValuationReport");

@Component({
  components: {
    DatePickerWithInput,
  },
})
export default class ProjectValuationPage10Edit extends Vue {
  @module.Getter("getObservations") observations!: string;
  @module.Mutation("setObservations") setObservations!: (value: string) => void;

  @module.Getter("getObservationTable") observationTable!: ObservationRow[];

  @module.Mutation("setObservationValueDriver") setObservationValueDriver!: (
    payload: MutationStringByIndexPayload
  ) => void;
  @module.Mutation("setObservationRiskDriver") setObservationRiskDriver!: (
    payload: MutationStringByIndexPayload
  ) => void;
}
