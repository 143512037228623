












import { Component, PropSync, Vue } from "vue-property-decorator";
import Steps from "@/components/common/Steps.vue";

@Component({
  components: {
    Steps,
  },
})
export default class ProjectValuationSteps extends Vue {
  @PropSync("value", { type: Number, required: true }) activeStep!: number;

  stepsNames = [
    this.$t("projectValuation.steps.marketPrice"),
    this.$t("projectValuation.steps.score"),
    this.$t("projectValuation.steps.buyers"),
    this.$t("projectValuation.steps.report"),
  ];

  get isMobile() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get stepsWidth(): number | null {
    if (this.$vuetify.breakpoint.mdAndUp) {
      return 600;
    }
    return null;
  }
}
