








































import { Component, Prop, Vue } from "vue-property-decorator";
import MarketPriceValuationTable from "@/components/project-valuation-report/MarketPriceValuationTable.vue";
import ProjectValuationMarketAnalysisTable from "@/components/project-valuation-market/ProjectValuationMarketAnalysisTable.vue";
import { ProjectValuationSnapshot } from "@/entity/project-valuation/ProjectValuationSnapshot";
import { DataTableHeader } from "vuetify";
import {
  salesOptions,
  transactionMultiplesOptions,
} from "../project-valuation-market/ProjectValuationMarketFilterOptions";

@Component({
  components: {
    MarketPriceValuationTable,
    ProjectValuationMarketAnalysisTable,
  },
})
export default class ProjectValuationSummaryMarketPrice extends Vue {
  @Prop({ type: Object, required: true }) snapshot!: ProjectValuationSnapshot;

  get finance() {
    return this.snapshot.projectFinance?.incomeStatement ?? [];
  }

  get transactionMultiplesOptions(): DataTableHeader[] {
    return transactionMultiplesOptions();
  }

  get salesOptions(): DataTableHeader[] {
    return salesOptions();
  }
}
