

































import { Component, Prop, Vue } from "vue-property-decorator";
import RangeSlider from "@/components/form/input/RangeSlider.vue";
import { ProjectValuationBuyerChart } from "@/entity/project-valuation/ProjectValuationBuyers";
import { BUYER_TYPE_DB_NAMES } from "@/constants";

@Component({
  components: {
    RangeSlider,
  },
})
export default class ProjectValuationBuyerTypeTiles extends Vue {
  @Prop({ type: String, required: true }) page!:
    | "stats"
    | "pdf"
    | "stats-report";
  @Prop({ type: Array, required: true })
  buyerTypes!: ProjectValuationBuyerChart[];

  rootStyle = {};
  containerStyle = {};

  get buyerData() {
    const privateBuyers =
      this.buyerTypes.find(
        (item) => item.name === BUYER_TYPE_DB_NAMES.privateBuyer
      )?.count || 0;
    const strategicBuyers =
      this.buyerTypes.find(
        (item) => item.name === BUYER_TYPE_DB_NAMES.strategicBuyer
      )?.count || 0;
    const financialInvestors =
      this.buyerTypes.find(
        (item) => item.name === BUYER_TYPE_DB_NAMES.financialInvestor
      )?.count || 0;

    const sortedTypes = this.buyerTypes
      .sort((a, b) => b.count - a.count)
      .filter((item) => item.name !== BUYER_TYPE_DB_NAMES.noSearchProfile);

    const response = {
      sorted: [0, 1, 2].map((item) =>
        sortedTypes[item]
          ? {
              title: this.buyerTypeName(
                sortedTypes[item].name as BUYER_TYPE_DB_NAMES
              ),
              count: sortedTypes[item].count,
            }
          : { title: "", count: "" }
      ),
      sum: privateBuyers + strategicBuyers + financialInvestors,
    };

    return response;
  }

  buyerTypeName(buyerTypeInDb: BUYER_TYPE_DB_NAMES) {
    switch (buyerTypeInDb) {
      case BUYER_TYPE_DB_NAMES.privateBuyer:
        return this.$t("buyerType.privateBuyer");
      case BUYER_TYPE_DB_NAMES.strategicBuyer:
        return this.$t("buyerType.strategicBuyer");
      case BUYER_TYPE_DB_NAMES.financialInvestor:
        return this.$t("buyerType.financialInvestor");
      default:
        return "";
    }
  }

  created() {
    if (this.page === "stats") {
      this.rootStyle = {
        height: "250px",
        width: "100%",
        maxWidth: "600px",
      };
    } else if (this.page === "pdf") {
      this.rootStyle = {
        height: "100%",
        width: "100%",
      };
      this.containerStyle = {
        fontSize: "1.3vw",
      };
    } else if (this.page === "stats-report") {
      this.rootStyle = {
        height: "100%",
        width: "100%",
      };
      this.containerStyle = {
        fontSize: "1.6em",
        // wordBreak: "break-all",
      };
    }
  }
}
