import { DEFAULT_MONEY_RANGE_VALUES } from "@/constants";

export const getPriceRangeForSlider = (
  from?: number | null,
  to?: number | null
): [number, number] => {
  const defaultSalesMin = from ?? null;
  const defaultSalesMax = to ?? null;

  const defaultRangeMin =
    defaultSalesMin === null ? 0 : closestStepToValue(defaultSalesMin);

  const defaultRangeMax =
    defaultSalesMax === null
      ? DEFAULT_MONEY_RANGE_VALUES.length - 1
      : closestStepToValue(defaultSalesMax);

  return [defaultRangeMin, defaultRangeMax];
};

const closestStepToValue = (salesPricePublished: number): number => {
  const closestNumber = DEFAULT_MONEY_RANGE_VALUES.reduce(function (
    prev,
    curr
  ) {
    return Math.abs(curr - salesPricePublished) <
      Math.abs(prev - salesPricePublished)
      ? curr
      : prev;
  });
  return DEFAULT_MONEY_RANGE_VALUES.findIndex((step) => step === closestNumber);
};
