










































import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import ProjectValuationSnapshotVersionSelect from "@/components/project-valuation-snapshot/ProjectValuationSnapshotVersionSelect.vue";
import ProjectValuationSnapshotFormDialog from "@/components/project-valuation-snapshot/ProjectValuationSnapshotFormDialog.vue";
import DialogMixin from "@/mixins/DialogMixin";
import { ProjectValuationSnapshotVersion } from "@/entity/project-valuation/ProjectValuationSnapshot";

@Component({
  components: {
    ProjectValuationSnapshotVersionSelect,
    ProjectValuationSnapshotFormDialog,
  },
})
export default class ProjectValuationSnapshotToolbar extends Mixins(
  DialogMixin
) {
  @Ref("snapshot-version-select")
  snapshotVersionSelect!: ProjectValuationSnapshotVersionSelect;

  @Prop({ type: Number, required: true }) projectId!: number;
  @Prop({ type: Boolean, required: true }) canOpenEditor!: boolean;

  nameOfEditedSnapshot: string | null = null;
  isDeleting = false;

  onSnapshotCreated(): void {
    this.snapshotVersionSelect.getSnapshotVersions();
    this.closeDialog();
    this.nameOfEditedSnapshot = null;
    this.isDeleting = false;
  }

  openSnapshotEditForm(item: ProjectValuationSnapshotVersion) {
    this.nameOfEditedSnapshot = item.name;
    this.openDialog("snapshotForm", item.id);
  }

  openSnapshotDeletionForm(item: ProjectValuationSnapshotVersion) {
    this.nameOfEditedSnapshot = item.name;
    this.isDeleting = true;
    this.openDialog("snapshotForm", item.id);
  }

  onSnapshotVersionSelected(versionId: number): void {
    this.$emit("version-selected", versionId);
  }

  onOpenReportEditorClick(): void {
    this.$emit("open-editor-clicked");
  }

  closeDialogLocal(): void {
    this.isDeleting = false;
    this.closeDialog();
  }
}
