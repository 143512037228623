


































































import { Component, Prop, Vue } from "vue-property-decorator";
import Footer from "@/components/project-valuation-report/Footer.vue";
import GraphScore from "@/components/project-valuation-report/GraphScore.vue";
import { ProjectScore } from "@/entity/project-valuation/ProjectValuationScore";
import Legend from "@/components/project-valuation-report/Legend.vue";
import { LegendItem } from "../interfaces";

@Component({
  components: {
    GraphScore,
    Footer,
    Legend,
  },
})
export default class ProjectValuationExternalReportPage15 extends Vue {
  @Prop({ type: Number, required: true }) footerPage!: number;
  @Prop({ type: String, required: true }) service!: string;
  @Prop({ type: Object, required: true }) score!: ProjectScore;
  @Prop({ type: String, required: true }) branchNames!: string;

  get legendItems(): LegendItem[] {
    return [
      {
        name: this.branchNames,
        color: "#224183",
      },
      {
        name: "Alle Branchen",
        color: "#3bdaa1",
      },
    ];
  }
}
