import { formatPrice } from "@/utils/string";
import { calculateAverage, pluckAndJoin } from "@/utils/array";
import { formatTimestamp } from "@/utils/date";
import { min } from "lodash";
import { ProjectFinanceDataRow } from "@/entity/ProjectFinance";
import ProjectValuationMarketFilterResult from "@/entity/project-valuation/ProjectValuationMarket";

export interface MarketPriceValuationResult {
  name: string;
  basisOfCalculation: number;
  from: number;
  to: number;
  key: string;
}

export type YearValueMap = { [key: number]: number };

export const formatValuationProject = (
  project: ProjectValuationMarketFilterResult
) => {
  return {
    ...project,
    project: {
      ...project.project,
      salesPricePublished: formatPrice(project.project.salesPricePublished),
      projectContact: {
        ...project.project.projectContact,
        contactBranches: pluckAndJoin(
          project.project.projectContact.contactBranches ?? [],
          "name"
        ),
        subBranches: pluckAndJoin(
          project.project.projectContact.subBranches ?? [],
          "name"
        ),
      },
    },
    incomeStatementMultiples: {
      turnover: formatPrice(project.incomeStatementMultiples.turnover),
      netProfit: formatPrice(project.incomeStatementMultiples.netProfit),
      ebt: formatPrice(project.incomeStatementMultiples.ebt),
      ebit: formatPrice(project.incomeStatementMultiples.ebit),
      ebitda: formatPrice(project.incomeStatementMultiples.ebitda),
    },
    projectSales: {
      ...project.projectSales,
      salesPrice: formatPrice(project.projectSales.salesPrice),
      fixedPrice: formatPrice(project.projectSales.fixedPrice),
      earnOutMax: formatPrice(project.projectSales.earnOutMax),
      ownerLoan: formatPrice(project.projectSales.ownerLoan),
      orderDate: formatTimestamp(project.projectSales.orderDate),
      startDate: formatTimestamp(project.projectSales.startDate),
      signing: formatTimestamp(project.projectSales.signing),
      calculationResults: {
        ...project.projectSales.calculationResults,
        fixedPriceRatio: `${project.projectSales.calculationResults?.fixedPriceRatio.toFixed(
          1
        )} %`,
        earnOutMaxPercentage: `${project.projectSales.calculationResults?.earnOutMaxPercentage.toFixed(
          1
        )} %`,
        ownerLoanPercentage: `${project.projectSales.calculationResults?.ownerLoanPercentage.toFixed(
          1
        )} %`,
        duration: project.projectSales.calculationResults?.duration.toFixed(1),
      },
    },
    transactionMultiples: {
      turnover: project.transactionMultiples.turnover?.toFixed(1),
      netProfit: project.transactionMultiples.netProfit?.toFixed(1),
      ebt: project.transactionMultiples.ebt?.toFixed(1),
      ebit: project.transactionMultiples.ebit?.toFixed(1),
      ebitda: project.transactionMultiples.ebitda?.toFixed(1),
    },
  };
};

export const formatValuationProjectForExcelExport = (
  project: ProjectValuationMarketFilterResult
) => {
  return {
    ...project,
    project: {
      ...project.project,
      salesPricePublished: project.project.salesPricePublished,
      projectContact: {
        ...project.project.projectContact,
        contactBranches: pluckAndJoin(
          project.project.projectContact.contactBranches ?? [],
          "name"
        ),
        subBranches: pluckAndJoin(
          project.project.projectContact.subBranches ?? [],
          "name"
        ),
      },
    },
    incomeStatementMultiples: {
      turnover: project.incomeStatementMultiples.turnover,
      netProfit: project.incomeStatementMultiples.netProfit,
      ebt: project.incomeStatementMultiples.ebt,
      ebit: project.incomeStatementMultiples.ebit,
      ebitda: project.incomeStatementMultiples.ebitda,
    },
    projectSales: {
      ...project.projectSales,
      salesPrice: project.projectSales.salesPrice,
      fixedPrice: project.projectSales.fixedPrice,
      earnOutMax: project.projectSales.earnOutMax,
      ownerLoan: project.projectSales.ownerLoan,
      orderDate: new Date(project.projectSales.orderDate || ""),
      startDate: new Date(project.projectSales.startDate || ""),
      signing: new Date(project.projectSales.signing || ""),
      calculationResults: {
        ...project.projectSales.calculationResults,
        fixedPriceRatio: Number(
          (
            Number(
              project.projectSales.calculationResults?.fixedPriceRatio.toFixed(
                1
              )
            ) / 100
          ).toFixed(2)
        ),
        earnOutMaxPercentage: Number(
          (
            Number(
              project.projectSales.calculationResults?.earnOutMaxPercentage.toFixed(
                1
              )
            ) / 100
          ).toFixed(2)
        ),
        ownerLoanPercentage: Number(
          (
            Number(
              project.projectSales.calculationResults?.ownerLoanPercentage.toFixed(
                1
              )
            ) / 100
          ).toFixed(2)
        ),
        duration: Number(
          project.projectSales.calculationResults?.duration.toFixed(1)
        ),
      },
    },
    transactionMultiples: {
      turnover: Number(project.transactionMultiples.turnover?.toFixed(1)),
      netProfit: Number(project.transactionMultiples.netProfit?.toFixed(1)),
      ebt: Number(project.transactionMultiples.ebt?.toFixed(1)),
      ebit: Number(project.transactionMultiples.ebit?.toFixed(1)),
      ebitda: Number(project.transactionMultiples.ebitda?.toFixed(1)),
    },
  };
};

export const resizableTable = (
  wrapper: HTMLElement,
  tableName: string,
  callback: (width: number) => void,
  initialWidth?: number | null
): void => {
  const tables = wrapper.getElementsByTagName("table");
  const wrapperToResize = wrapper.getElementsByClassName(
    "table-wrapper"
  )[0] as HTMLElement;

  if (tables.length === 0 || !wrapper) return;

  const table = tables[0];
  const resizer = wrapper.getElementsByClassName("resizer")[0] as HTMLElement;

  setListeners(resizer);

  function setListeners(resizer: HTMLElement) {
    let pageX = 0;
    let currentWidth = 0;
    let firstHeader: HTMLElement | null = null;

    if (initialWidth) {
      wrapperToResize.style.width = initialWidth + "px";
      wrapperToResize.style.maxWidth = initialWidth + "px";
    }

    function onMouseMove(e: MouseEvent) {
      if (!firstHeader) return;

      const diffX = e.pageX - pageX;
      const minWidth = firstHeader.offsetWidth - paddingDiff(firstHeader);
      const maxWidth = table.offsetWidth - paddingDiff(table);
      const newWidth = getNewWidth(diffX, minWidth, maxWidth, currentWidth);

      wrapperToResize.style.width = newWidth + "px";
      wrapperToResize.style.maxWidth = newWidth + "px";
      callback(newWidth);
    }

    resizer.addEventListener("mousedown", function (e: MouseEvent) {
      firstHeader = table.getElementsByTagName("th")[0];

      document.addEventListener("mousemove", onMouseMove);

      if (e.target) {
        pageX = e.pageX;

        const padding = paddingDiff(wrapper);

        currentWidth = wrapperToResize.offsetWidth - padding;
      }
    });

    document.addEventListener("mouseup", function () {
      pageX = 0;
      currentWidth = 0;
      firstHeader = null;

      document.removeEventListener("mousemove", onMouseMove);
    });
  }

  const getNewWidth = (
    diffX: number,
    minWidth: number,
    maxWidth: number,
    currentWidth: number
  ): number => {
    let cc = currentWidth + diffX;
    if (cc < minWidth) {
      cc = minWidth;
    } else if (cc > maxWidth) {
      cc = maxWidth;
    }

    return cc;
  };

  const paddingDiff = (col: HTMLElement) => {
    if (getStyleVal(col, "box-sizing") == "border-box") {
      return 0;
    }

    const padLeft = getStyleVal(col, "padding-left");
    const padRight = getStyleVal(col, "padding-right");
    return parseInt(padLeft) + parseInt(padRight);
  };

  const getStyleVal = (elm: HTMLElement, css: string) => {
    return window.getComputedStyle(elm, null).getPropertyValue(css);
  };
};

export const marketPriceValuationMultipleTranslate = (
  calculatedMultiple: string
): string => {
  switch (calculatedMultiple) {
    case "turnover": {
      return "betriebsertrag";
    }
    case "netProfit": {
      return "reingewinn";
    }
    default: {
      return calculatedMultiple;
    }
  }
};

export const yearValueMap = (
  projectFinance: ProjectFinanceDataRow[],
  calculatedMultiple: string
): YearValueMap => {
  const response: { [key: number]: number } = {};

  const incomeStatement = projectFinance.find(
    (variable) =>
      variable.variable.toLowerCase() === calculatedMultiple.toLowerCase()
  );

  if (incomeStatement) {
    incomeStatement.data.forEach((year) => {
      response[year.year] = year.value as number;
    });
  }

  return response;
};

export const rangeAverage = (
  from: number,
  to: number,
  map: { [key: number]: number }
): number => {
  let total = 0;
  let totalNumbers = 0;
  const years = Object.keys(map);

  years.forEach((year) => {
    const yearInt = parseInt(year);

    if (yearInt >= from && yearInt <= to) {
      total = total + map[yearInt];
      totalNumbers = totalNumbers + 1;
    }
  });

  return total / totalNumbers;
};

export const formatMarketPriceValuation = (
  yearValueMap: YearValueMap,
  baseYear: number,
  calculatedMultiple: string,
  minMultiple: number,
  maxMultiple: number
) => {
  const result: MarketPriceValuationResult[] = [];

  // if (
  //   !this.marketPrice.valuationOptions?.baseYear ||
  //   !this.marketPrice.valuationOptions?.calculatedMultiple
  // ) {
  //   this.results = [];
  //   return;
  // }

  const boc = yearValueMap[baseYear] ?? 0;
  const minYear = parseInt(min(Object.keys(yearValueMap)) ?? "");

  result.push({
    key: `${baseYear}`,
    name: `${calculatedMultiple} ${baseYear}`,
    basisOfCalculation: boc,
    from: boc * minMultiple,
    to: boc * maxMultiple,
  });

  if (!Number.isNaN(minYear)) {
    for (let i = minYear; i < baseYear; i++) {
      const basis = rangeAverage(i, baseYear, yearValueMap);
      result.push({
        key: `${i}-${baseYear}`,
        name: `${calculatedMultiple} der Jahre ${i} - ${baseYear}; Mittelwert`,
        basisOfCalculation: basis,
        from: basis * minMultiple,
        to: basis * maxMultiple,
      });

      if (baseYear - i === 1) {
        const weightedBasis = calculateAverage([
          yearValueMap[baseYear],
          yearValueMap[baseYear],
          yearValueMap[i],
        ]);
        result.push({
          key: `${i}-${baseYear}:2`,
          name: `${calculatedMultiple} der Jahre ${i} - ${baseYear}; Gewichtet (1:2)`,
          basisOfCalculation: weightedBasis,
          from: weightedBasis * minMultiple,
          to: weightedBasis * maxMultiple,
        });
      }
    }
  }

  return result;
};
