














import { Component, Vue, Prop } from "vue-property-decorator";
import { LegendItem } from "@/components/project-valuation-report/interfaces";

@Component
export default class Legend extends Vue {
  @Prop({ type: Array, required: true }) items!: LegendItem[];
}
