







































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  Page1,
  Page2,
  Page3,
  Page4,
  Page5,
  Page6,
  Page7,
  Page8,
  Page9,
  Page10,
  Page11,
  Page12,
  Page13,
  Page14,
  Page15,
  Page16,
  Page17,
  Page18,
  Page19,
} from "@/components/project-valuation-report/pages";
import {
  AdjustmentPage,
  ProjectValuationReportUserInput,
} from "@/store/projectValuationReport/types";
import { ProjectValuationSnapshot } from "@/entity/project-valuation/ProjectValuationSnapshot";
import { findBreaksInText } from "@/store/projectValuationReport/util";
import { splitAt } from "@/utils/string";
import { ProjectValuationBuyerChart } from "@/entity/project-valuation/ProjectValuationBuyers";
import { namespace } from "vuex-class";

const module = namespace("projectValuationReport");

@Component({
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
    Page8,
    Page9,
    Page10,
    Page11,
    Page12,
    Page13,
    Page14,
    Page15,
    Page16,
    Page17,
    Page18,
    Page19,
  },
})
export default class ProjectValuationReport extends Vue {
  @module.Getter("getBuyerTypes")
  buyerTypesFromUserInput!: ProjectValuationBuyerChart[] | null;
  @module.Mutation("setBuyerTypes")
  setBuyerTypes!: (value: ProjectValuationBuyerChart[]) => void;
  @Prop({ type: Number, required: false, default: null }) page!: number | null;
  @Prop({ type: Object, required: true }) snapshot!: ProjectValuationSnapshot;
  @Prop({ type: Object, required: true })
  reportUserInput!: ProjectValuationReportUserInput;

  visiblePages = Array(19)
    .fill(0)
    .map((_, i) => i + 1);
  isReportOpenForDownload = this.$route.path.includes(
    "/projects/valuation-report/"
  );

  get financeData() {
    return this.snapshot.projectFinance?.incomeStatement ?? [];
  }

  get adjustments() {
    return this.snapshot.projectFinance?.adjustments ?? [];
  }

  showPage(page: number) {
    if (this.isReportOpenForDownload && !this.visiblePages.includes(page)) {
      return false;
    }
    if (this.page === null) return true;
    return this.page === page;
  }

  get adjustmentPages(): AdjustmentPage[] {
    const keys = Object.keys(this.reportUserInput.adjustments);
    let index = 0;
    const pages: AdjustmentPage[] = [];
    const breaks = findBreaksInText(this.reportUserInput.adjustments, keys);

    for (const key of keys) {
      const text = this.reportUserInput.adjustments[key];
      const br = breaks.find((br) => br.key === key);

      if (pages.find((page) => page.index === index) === undefined) {
        pages.push({
          index,
          adjustments: {},
        });
      }

      if (br === undefined) {
        if (text.length > 0) {
          pages[index].adjustments[key] = text;
        }
      } else {
        const textParts = splitAt(text, br.index);
        pages[index].adjustments[key] = textParts[0];
        index++;
        pages.push({
          index,
          adjustments: {},
        });
        pages[index].adjustments[key] = textParts[1].replace("\\p", "");
      }
    }

    return pages;
  }

  get additionalPagesAmount(): number {
    if (this.adjustmentPages.length > 1) {
      return this.adjustmentPages.length - 1;
    }

    return 0;
  }

  async created() {
    this.setBuyerTypes(this.snapshot.buyersStats.buyerTypes);
    if (this.isReportOpenForDownload) {
      this.visiblePages = (this.$route.query.pages as string)
        .split(",")
        .map((item) => Number(item));
    }
  }

  footerNumber(page: number) {
    return this.visiblePages.findIndex((item) => item === page) + 1;
  }

  chapterNumber(chapter: "marktpreis" | "datenbank" | "btScore") {
    if (!this.isReportOpenForDownload) {
      if (chapter === "marktpreis") return 1;
      if (chapter === "datenbank") return 2;
      if (chapter === "btScore") return 3;
    }
    if (chapter === "datenbank" && this.showPage(5)) return 2;
    if (chapter === "btScore" && this.showPage(5) && this.showPage(12))
      return 3;
    if (chapter === "btScore" && (this.showPage(5) || this.showPage(12)))
      return 2;
    return 1;
  }
}
