





























import { Vue, Component, Prop } from "vue-property-decorator";
import Project from "@/entity/Project";

@Component
export default class ProjectValuationBuyersDataTableToolbar extends Vue {
  @Prop({ required: true, type: Object }) project!: Project;
  @Prop({ type: Number, required: true }) selected!: number;
  @Prop({ required: true }) colNum!: number;
  @Prop({ default: false }) isMobile!: boolean;
}
