
































import { Component, Vue, Prop } from "vue-property-decorator";
import Rating from "@/components/Rating.vue";
import ValueOrNA from "@/components/common/ValueOrNA.vue";
import ScoreTableTotalRow from "@/components/project-valuation-score/ProjectValuationScoreTableTotalRow.vue";
import { FinancialViabilityScore } from "@/entity/project-valuation/ProjectValuationScore";

@Component({
  components: {
    Rating,
    ValueOrNA,
    ScoreTableTotalRow,
  },
})
export default class FinancialViabilityScoreTable extends Vue {
  @Prop({ type: Object }) financialViabilityScore!: FinancialViabilityScore;

  get scores() {
    return [
      {
        key: "turnoverVolatility",
        name: this.$t("valuation.score.financialViability.turnoverVolatility"),
        score: `${this.financialViabilityScore.turnoverVolatility.value} %`,
        overallScore: `${this.financialViabilityScore.turnoverVolatility.overallValue} %`,
        rating: this.financialViabilityScore.turnoverVolatility.rating,
        isPercentage: true,
      },
      {
        key: "earningsVolatility",
        name: this.$t("valuation.score.financialViability.earningsVolatility"),
        score: `${this.financialViabilityScore.earningsVolatility.value} %`,
        overallScore: `${this.financialViabilityScore.earningsVolatility.overallValue} %`,
        rating: this.financialViabilityScore.earningsVolatility.rating,
        isPercentage: false,
      },
      {
        key: "liquidRation2",
        name: this.$t("valuation.score.financialViability.liquidRation2"),
        score: `${this.financialViabilityScore.liquidRation2.value} %`,
        overallScore: `${this.financialViabilityScore.liquidRation2.overallValue} %`,
        rating: this.financialViabilityScore.liquidRation2.rating,
        isPercentage: true,
      },
    ];
  }
}
