
































import { Component, Prop, Vue } from "vue-property-decorator";
import Footer from "@/components/project-valuation-report/Footer.vue";
import DeltaScore from "@/components/project-valuation-report/DeltaScore.vue";
import DeltaLegend from "@/components/project-valuation-report/DeltaLegend.vue";
import { ProjectValuationReportPeerGroupItem } from "@/store/projectValuationReport/types";

@Component({
  components: {
    DeltaScore,
    Footer,
    DeltaLegend,
  },
})
export default class ProjectValuationExternalReportPage9 extends Vue {
  @Prop({ type: Number, required: true }) footerPage!: number;
  @Prop({ type: String, required: true }) companyName!: string;
  @Prop({ type: Array, required: true })
  data!: ProjectValuationReportPeerGroupItem[];
}
