








































































import { Component, Prop, Vue } from "vue-property-decorator";
import Project from "@/entity/Project";
import ProjectValuationScoreIndustryTable from "@/components/project-valuation-score/ProjectValuationScoreIndustryTable.vue";
import ProjectValuationScoreRiskTable from "@/components/project-valuation-score/ProjectValuationScoreRiskTable.vue";
import ProjectValuationScoreStructure from "@/components/project-valuation-score/ProjectValuationScoreStructure.vue";
import ProjectValuationScoreFinancialViabilityTable from "@/components/project-valuation-score/ProjectValuationScoreFinancialViabilityTable.vue";
import MissingDataText from "@/components/common/MissingDataText.vue";
import ProjectValuationScoreFinalTable from "@/components/project-valuation-score/ProjectValuationScoreFinalTable.vue";
import SectionTitle from "@/components/detail-page/SectionTitle.vue";
import ProjectValuationScoreService from "@/services/projectValuation/ProjectValuationScoreService";
import {
  ProjectScore,
  ProjectScoreAnswerInput,
} from "@/entity/project-valuation/ProjectValuationScore";

@Component({
  components: {
    ProjectValuationScoreIndustryTable,
    ProjectValuationScoreRiskTable,
    ProjectValuationScoreStructure,
    ProjectValuationScoreFinancialViabilityTable,
    MissingDataText,
    ProjectValuationScoreFinalTable,
    SectionTitle,
  },
})
export default class ProjectValuationScore extends Vue {
  @Prop({ required: true, type: Object }) project!: Project;
  @Prop({ required: true, type: Boolean }) hasEditRights!: boolean;

  isFetchingProjectScore = false;
  isSaving = false;
  projectScore: ProjectScore | null = null;

  data: ProjectScoreAnswerInput = {
    ownerDependence: null,
    organisationSize: null,
    staffTurnover: null,
    customerClusterRisk: null,
    locationBound: null,
    estate: null,
    revisedFinancialStatements: null,
    evaluability: null,
  };

  setInitialData(projectScore: ProjectScore) {
    this.data.ownerDependence =
      projectScore.riskScore.ownerDependence.value ?? null;
    this.data.organisationSize =
      projectScore.riskScore.organisationSize.value ?? null;
    this.data.staffTurnover =
      projectScore.riskScore.staffTurnover.value ?? null;
    this.data.customerClusterRisk =
      projectScore.riskScore.customerClusterRisk.value ?? null;
    this.data.locationBound =
      projectScore.structureScore.locationBound.value ?? null;
    this.data.estate = projectScore.structureScore.estate.value ?? null;
    this.data.revisedFinancialStatements =
      projectScore.structureScore.revisedFinancialStatements.value ?? null;
    this.data.evaluability =
      projectScore.structureScore.evaluability.value ?? null;
  }

  async fetchProjectScore(): Promise<void> {
    try {
      this.isFetchingProjectScore = true;
      this.projectScore = await ProjectValuationScoreService.get(
        this.project.id as number
      );
      this.setInitialData(this.projectScore);
      if (this.projectScore.riskScore.rating === null) {
        await this.saveScoreAnswers();
      }
    } catch (e) {
      if (this.$te(`apiErrors.${e.response.data.message}`)) {
        this.$snackbarError(this.$t(`apiErrors.${e.response.data.message}`));
        return;
      }

      this.$snackbarError(this.$t("apiErrors.unableToLoad"));
    } finally {
      this.isFetchingProjectScore = false;
    }
  }

  async saveScoreAnswers(): Promise<void> {
    Object.keys(this.data).forEach((item) => {
      // @ts-ignore
      if (this.data[item] === "null") {
        // @ts-ignore
        this.data[item] = "no";
      }
    });
    try {
      this.isSaving = true;
      await ProjectValuationScoreService.saveAnswers(
        this.project.id as number,
        this.data
      );
    } catch (e) {
      this.$snackbarError(this.$t("apiErrors.unableToSave"));
    } finally {
      this.isSaving = false;
    }
  }

  async onSave() {
    await this.saveScoreAnswers();
    await this.fetchProjectScore();
  }

  created() {
    this.fetchProjectScore();
  }
}
