import { ProjectValuationMarketMultiples } from "@/entity/project-valuation/ProjectValuationMarket";
import {
  calculateAverage,
  calculateCuttingMeans,
  calculateMedian,
} from "@/utils/array";
import { formatPrice } from "@/utils/string";

type CalculationMethod = (numbers: number[]) => string;

export const calculateAdditionalRowValues = (
  type: string,
  headerValue: string,
  flatValues: ProjectValuationMarketMultiples
) => {
  const cuttingMeansTrim = 0.2;
  let title = "";
  let calculationMethod: null | CalculationMethod = null;

  switch (type) {
    case "average": {
      title = "Mittelwert";
      calculationMethod = (value: number[]) =>
        calculateAverage(value, true).toFixed(1);
      break;
    }
    case "median": {
      title = "Median";
      calculationMethod = (value: number[]) =>
        calculateMedian(value).toFixed(1);
      break;
    }
    case "cutting": {
      title = "Gestutzter Mittelwert";
      calculationMethod = (value: number[]) =>
        calculateCuttingMeans(value, cuttingMeansTrim).toFixed(1);
      break;
    }
  }

  let value: undefined | string = undefined;
  let cellClass = "text-right";
  let isNumber = true;
  let isPercentage = false;

  switch (headerValue) {
    case "project.projectContact.name": {
      value = title;
      cellClass = "text-left";
      isNumber = false;
      break;
    }
    case "transactionMultiples.turnover": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.transactionMultiples.turnover);
      }
      break;
    }
    case "transactionMultiples.netProfit": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.transactionMultiples.netProfit);
      }
      break;
    }
    case "transactionMultiples.ebt": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.transactionMultiples.ebt);
      }
      break;
    }
    case "transactionMultiples.ebit": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.transactionMultiples.ebit);
      }
      break;
    }
    case "transactionMultiples.ebitda": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.transactionMultiples.ebitda);
      }
      break;
    }
    case "projectSales.salesPrice": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.salesPrice);
      }
      break;
    }
    case "projectSales.fixedPrice": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.fixedPrice);
      }
      break;
    }
    case "projectSales.calculationResults.fixedPriceRatio": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.fixedPriceRatio);
      }
      isPercentage = true;
      break;
    }
    case "projectSales.earnOutMax": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.earnOutMax);
      }
      break;
    }
    case "projectSales.calculationResults.earnOutMaxPercentage": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.earnOutMaxPercentage);
      }
      isPercentage = true;
      break;
    }
    case "projectSales.ownerLoan": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.ownerLoan);
      }
      break;
    }
    case "projectSales.calculationResults.ownerLoanPercentage": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.ownerLoanPercentage);
      }
      isPercentage = true;
      break;
    }
    case "projectSales.calculationResults.duration": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.duration);
      }
      break;
    }
    case "project.salesPricePublished": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.salesPricePublished);
      }
      break;
    }
    case "project.projectContact.employees": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.employees);
      }
      break;
    }
    case "project.projectContact.fullTimeEquivalent": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.fullTimeEquivalent);
      }
      break;
    }
    case "incomeStatementMultiples.turnover": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.incomeStatementMultiples.turnover);
      }
      break;
    }
    case "incomeStatementMultiples.netProfit": {
      if (calculationMethod) {
        value = calculationMethod(
          flatValues.incomeStatementMultiples.netProfit
        );
      }
      break;
    }
    case "incomeStatementMultiples.ebt": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.incomeStatementMultiples.ebt);
      }
      break;
    }
    case "incomeStatementMultiples.ebit": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.incomeStatementMultiples.ebit);
      }
      break;
    }
    case "incomeStatementMultiples.ebitda": {
      if (calculationMethod) {
        value = calculationMethod(flatValues.incomeStatementMultiples.ebitda);
      }
      break;
    }
    default: {
      isNumber = false;
      break;
    }
  }

  let finalValue = !isNumber
    ? value
    : formatPrice(Math.round(Number(value))).toString();
  if (isPercentage) finalValue += "%";

  return {
    finalValue,
    cellClass,
    isNumber,
    isPercentage,
    originalValue: Number(value),
  };
};
