

























import { Component, Mixins, Prop } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import RulesMixin from "@/mixins/RulesMixin";
import BaseForm from "@/components/form/BaseForm";
import ProjectValuationSnapshotService from "@/services/projectValuation/ProjectValuationSnapshotService";

@Component({
  components: {
    FormCard,
  },
})
export default class ProjectValuationSnapshotFormDialog extends Mixins(
  RulesMixin,
  BaseForm
) {
  @Prop({ type: Number, required: true }) projectId!: number;
  @Prop({ type: Number, default: null }) snapshotId!: number | null;
  @Prop({ type: String, default: null }) initialName!: string | null;
  @Prop({ type: Boolean, default: false }) deleteSnapshot!: boolean;

  name = "";
  isSaving = false;

  get trimmedName(): string {
    return this.name.trim();
  }

  get titleTranslationKey(): string {
    if (this.snapshotId === null) {
      return "valuation.report.save";
    }
    if (this.deleteSnapshot) {
      return "valuation.report.delete";
    }
    return "valuation.report.editName";
  }

  onFileNameKeypress(event: KeyboardEvent): void {
    if (event.key === "Enter" && this.trimmedName.length > 0) {
      event.preventDefault();
      this.handleFormSubmit(this.handleForm);
    }
  }

  async handleForm(): Promise<number> {
    if (this.deleteSnapshot && this.snapshotId !== null) {
      return await ProjectValuationSnapshotService.remove(
        this.projectId,
        this.snapshotId
      );
    }

    if (this.snapshotId !== null) {
      return await ProjectValuationSnapshotService.edit(
        this.projectId,
        this.snapshotId,
        this.trimmedName
      );
    }

    return await ProjectValuationSnapshotService.create(
      this.projectId,
      this.trimmedName
    );
  }

  created() {
    if (this.initialName !== null) {
      this.name = this.initialName;
    }
  }
}
