




























































import { Component, Vue, Prop } from "vue-property-decorator";
import { ProjectFinanceDataRow } from "@/entity/ProjectFinance";
import { formatPrice } from "@/utils/string";
import {
  formatMarketPriceValuation,
  marketPriceValuationMultipleTranslate,
  MarketPriceValuationResult,
  yearValueMap,
} from "@/utils/projectValuation";
import {
  MarketPriceData,
  ValuationInputs,
} from "@/services/projectValuation/ProjectValuationMarketService";

@Component
export default class MarketPriceValuationTable extends Vue {
  @Prop({ type: String, required: true }) companyName!: string;
  @Prop({ type: Object, required: true }) marketPrice!: MarketPriceData;
  @Prop({ type: Array }) projectFinance!: ProjectFinanceDataRow[];
  @Prop({ type: String, required: true }) branchNames!: string;
  @Prop({ type: String, required: true }) subBranchNames!: string;

  results: MarketPriceValuationResult[] = [];

  formatPrice = formatPrice;

  get inputData(): ValuationInputs {
    return {
      minMultipleValue: this.marketPrice.valuationInputs?.minMultipleValue ?? 0,
      maxMultipleValue: this.marketPrice.valuationInputs?.maxMultipleValue ?? 0,
      averageMin: this.marketPrice.valuationInputs?.averageMin ?? 0,
      averageMax: this.marketPrice.valuationInputs?.averageMax ?? 0,
    };
  }

  get multipleTranslate(): string | null {
    if (!this.marketPrice.valuationOptions?.calculatedMultiple) return null;

    return marketPriceValuationMultipleTranslate(
      this.marketPrice.valuationOptions?.calculatedMultiple
    );
  }

  get selectedMultipleTranslation(): string {
    if (!this.marketPrice.valuationOptions?.calculatedMultiple) return "";
    return this.$t(
      this.marketPrice.valuationOptions?.calculatedMultiple
    ).toString();
  }

  get yearValueMap(): { [key: number]: number } {
    if (this.multipleTranslate === null) return {};

    return yearValueMap(this.projectFinance, this.multipleTranslate);
  }

  getResults(): void {
    if (!this.marketPrice.valuationOptions?.baseYear) {
      this.results = [];
      return;
    }

    this.results = formatMarketPriceValuation(
      this.yearValueMap,
      this.marketPrice.valuationOptions?.baseYear,
      this.selectedMultipleTranslation,
      this.inputData.minMultipleValue,
      this.inputData.maxMultipleValue
    );
  }

  get resultsToShow(): MarketPriceValuationResult[] {
    if (!this.marketPrice.excludedValuationResults) {
      return this.results;
    }

    return this.results.filter(
      (result) =>
        !this.marketPrice.excludedValuationResults?.includes(result.key) ?? true
    );
  }

  created() {
    this.getResults();
  }
}
