import {
  AdjustmentPageBreak,
  ProjectValuationAdjustments,
} from "@/store/projectValuationReport/types";

export const findBreaksInText = (
  adjustments: ProjectValuationAdjustments,
  keys: string[]
): AdjustmentPageBreak[] => {
  const breaks: AdjustmentPageBreak[] = [];
  for (const key of keys) {
    const text = adjustments[key];
    const breakIndex = text.indexOf("\\p");

    if (breakIndex > -1) {
      breaks.push({
        key,
        index: breakIndex,
      });
    }
  }
  return breaks;
};
