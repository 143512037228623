

































import { Component, Prop, Vue } from "vue-property-decorator";
import Footer from "@/components/project-valuation-report/Footer.vue";
import ObservationsTable from "@/components/project-valuation-report/ObservationsTable.vue";
import { ObservationRow } from "@/store/projectValuationReport/types";

@Component({
  components: {
    Footer,
    ObservationsTable,
  },
})
export default class ProjectValuationExternalReportPage10 extends Vue {
  @Prop({ type: Number, required: true }) footerPage!: number;
  @Prop({ type: String, required: true }) observations!: string;
  @Prop({ type: Array, required: true }) observationsTable!: ObservationRow[];
}
