





























import { Component, Prop, Vue } from "vue-property-decorator";
import ProjectValuationMarket from "@/components/project-valuation-market/ProjectValuationMarket.vue";
import ProjectValuationScore from "@/components/project-valuation-score/ProjectValuationScore.vue";
import ProjectValuationBuyers from "@/components/project-valuation-buyers/ProjectValuationBuyers.vue";
import ProjectValuationSnapshot from "@/components/project-valuation-snapshot/ProjectValuationSnapshot.vue";
import Project from "@/entity/Project";
import ProjectValuationSteps from "@/components/project-valuation/ProjectValuationSteps.vue";

@Component({
  components: {
    ProjectValuationMarket,
    ProjectValuationScore,
    ProjectValuationBuyers,
    ProjectValuationSnapshot,
    ProjectValuationSteps,
  },
})
export default class ProjectValuationView extends Vue {
  @Prop({ required: true, type: Object }) project!: Project;

  activeStep = 1;

  get hasEditRights(): boolean {
    return this.project.projectOperations?.hasConsultantRights ?? false;
  }
}
