




import { Component, Prop, Vue } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { get } from "lodash";
import { ApexOptions } from "apexcharts";
import { MAIN_MATERIAL_COLOR_LIST } from "@/constants";
import { ProjectValuationBuyerChart } from "@/entity/project-valuation/ProjectValuationBuyers";

@Component({
  components: {
    VueApexCharts,
  },
})
export default class NumberOfBuyersBarChart extends Vue {
  @Prop({ required: true, type: Array }) data!: ProjectValuationBuyerChart[];

  get labels(): string[] {
    return (
      this.data.map((stat) =>
        this.$t(`valuation.buyers.matchType.${stat.name}`).toString()
      ) ?? []
    );
  }

  get values(): number[] {
    return this.data.map((item) => get(item, "count"));
  }

  get series() {
    return [{ data: this.values, name: "" }];
  }

  get options(): ApexOptions {
    return {
      colors: MAIN_MATERIAL_COLOR_LIST,
      title: {
        text: this.$tc("numberOfBuyers", 1).toString(),
        align: "center",
      },
      chart: {
        type: "bar",
        height: 600,
        toolbar: {
          show: false,
        },
      },
      labels: this.labels,
      legend: {
        show: true,
        position: "bottom",
        customLegendItems: ["1", "2", "3"],
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
    };
  }
}
