















import { Component, Vue, Prop } from "vue-property-decorator";
import Rating from "@/components/project-valuation-report/Rating.vue";

@Component({
  components: {
    Rating,
  },
})
export default class ValueScore extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: Number, required: true }) value!: number;
  @Prop({ type: Number, required: true }) rating!: string;
  @Prop({ type: Boolean, default: false }) isPercentage!: string;

  get readableValue(): string {
    if (this.isPercentage) return `${this.value.toFixed(0)}%`;
    return this.value.toFixed(0);
  }
}
