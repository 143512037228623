





































import { Component, Vue, Prop, PropSync } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import ProjectsService from "@/services/ProjectsService";
import { numberArrayRange } from "@/utils/array";
import { FinanceYears } from "@/entity/Project";

@Component
export default class ProjectValuationMarketValuationSidebar extends Vue {
  @Prop({ type: Number, required: true }) projectId!: number;
  @Prop({ required: true, type: Boolean }) hasEditRights!: boolean;

  @PropSync("calculatedMultiple", { type: String })
  calculatedMultipleLocal!: string | null;
  @PropSync("baseYear", { type: Number }) baseYearLocal!: number | null;

  financeYears: FinanceYears | null = null;
  isLoading = false;

  get transactionMultiplesOptions(): DataTableHeader[] {
    return [
      {
        text: this.$t("turnover").toString(),
        value: "turnover",
      },
      {
        text: this.$t("netProfit").toString(),
        value: "netProfit",
      },
      {
        text: this.$t("ebt").toString(),
        value: "ebt",
      },
      {
        text: this.$t("ebit").toString(),
        value: "ebit",
      },
      {
        text: this.$t("ebitda").toString(),
        value: "ebitda",
      },
    ];
  }

  get baseYearOptions(): number[] {
    if (this.financeYears === null) return [];
    return numberArrayRange(
      this.financeYears.firstYear,
      this.financeYears.lastYear
    );
  }

  async fetchFinanceYears(): Promise<void> {
    try {
      this.isLoading = true;
      this.financeYears = await ProjectsService.getFinanceYears(
        this.projectId as number
      );
    } finally {
      this.isLoading = false;
    }
  }

  created() {
    this.fetchFinanceYears();
  }
}
