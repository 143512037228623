




































import { Component, Prop, Vue } from "vue-property-decorator";
import ProjectValuationReport from "@/components/project-valuation-report/ProjectValuationReport.vue";
import moment from "moment";
import { DATE_FORMAT } from "@/constants";
import { ProjectValuationSnapshot } from "@/entity/project-valuation/ProjectValuationSnapshot";
import fileDownload from "js-file-download";
import ProjectValuationReportService from "@/services/projectValuation/ProjectValuationReportService";

@Component({
  components: {
    ProjectValuationReport,
  },
})
export default class ProjectValuationReportDownload extends Vue {
  @Prop({ type: Object, required: true })
  snapshot!: ProjectValuationSnapshot;
  @Prop({ type: Array, required: true }) pagesForDownload!: {
    page: number;
    included: boolean;
  }[];

  isDownloading = false;
  dialog = false;

  get fileName(): string {
    const date = moment().format(DATE_FORMAT);
    return `${this.snapshot.companyName}_valuation_report_${date}.pdf`;
  }

  async onDownloadClick(): Promise<void> {
    try {
      this.dialog = false;
      this.isDownloading = true;
      await this.download();
    } finally {
      this.isDownloading = false;
    }
  }

  async download(): Promise<void> {
    try {
      const pdf = await ProjectValuationReportService.downloadPdf(
        this.snapshot.id,
        `pages=${this.pagesForDownload
          .filter((item) => item.included)
          .map((item) => item.page)
          .join(",")}`
      );
      await fileDownload(pdf, this.fileName);
    } catch (e) {
      this.$snackbarError("Unable to download PDF");
    }
  }
}
