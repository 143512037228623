












import { Component, Prop, Vue } from "vue-property-decorator";
import Rating from "@/components/project-valuation-report/Rating.vue";

@Component({
  components: {
    Rating,
  },
})
export default class Answer extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: Number, required: true }) rating!: string;
  @Prop({ type: String, required: true }) answer!: string;

  translatedAnswer(answer: string): string {
    if (answer.toLowerCase() === "yes") {
      return this.$t("yes").toString();
    } else if (answer.toLowerCase() === "no") {
      return this.$t("no").toString();
    } else if (answer.toLowerCase() === "partly") {
      return this.$t("partly").toString();
    }

    return "";
  }
}
