import Axios from "axios";
import { TableQuery } from "@/components/data-table/types";
import { ListResponseEntity } from "@/services/types";
import Contact from "@/entity/Contact";
import filterQueryParams from "@/services/filterQueryParams";
import {
  ProjectValuationBuyersFilter,
  ProjectValuationBuyersFilterInput,
  ProjectValuationBuyerStats,
} from "@/entity/project-valuation/ProjectValuationBuyers";

export default class ProjectValuationBuyersService {
  static async add(
    projectId: number,
    filter: ProjectValuationBuyersFilterInput
  ) {
    const { data } = await Axios.post(
      `/api/v2/valuation/buyers/${projectId}`,
      filter
    );
    return data;
  }

  static async list(
    projectId: string | number,
    query: TableQuery
  ): Promise<ListResponseEntity<Contact>> {
    /**
     * API Ignore the projectId in parameter and only uses the query params
     */
    const response = await Axios.get<ListResponseEntity<any>>(
      `/api/v2/valuation/buyers/${projectId}`,
      {
        params: {
          ...filterQueryParams(query),
        },
      }
    );

    return response.data;
  }

  static async delete(
    projectId: number,
    contactIds: number[]
  ): Promise<number> {
    const { data } = await Axios.delete(
      `/api/v2/valuation/buyers/${projectId}`,
      {
        params: {
          contactIds: contactIds.toString(),
        },
      }
    );
    return data;
  }

  static async stats(projectId: number): Promise<ProjectValuationBuyerStats> {
    const { data } = await Axios.get(
      `/api/v2/valuation/buyers/${projectId}/stats`
    );
    return data;
  }

  static async filter(
    projectId: number
  ): Promise<ProjectValuationBuyersFilter> {
    const { data } = await Axios.get(
      `/api/v2/valuation/buyers/${projectId}/filter`
    );
    return data;
  }
}
