

















































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import DatePickerWithInput from "@/components/form/input/DatePickerWithInput.vue";
import {
  EditEndTimelineItemPayload,
  ProjectValuationEndTimeline,
  ProjectValuationReportEndTimelineItem,
} from "@/store/projectValuationReport/types";
import EndTimelineItemEdit from "@/components/project-valuation-report/EndTimelineItemEdit.vue";

const module = namespace("projectValuationReport");

@Component({
  components: {
    DatePickerWithInput,
    EndTimelineItemEdit,
  },
})
export default class ProjectValuationPage17Edit extends Vue {
  @module.Getter("getEndQuote")
  endQuote!: string;
  @module.Mutation("setEndQuote")
  setEndQuote!: (value: string) => void;

  @module.Getter("getEndTimeline") endTimeline!: ProjectValuationEndTimeline;
  @module.Mutation("editEndTimelineItem")
  editEndTimelineItem!: (payload: EditEndTimelineItemPayload) => void;
  @module.Mutation("addEndTimelineItem")
  addEndTimelineItem!: () => void;
  @module.Mutation("deleteEndTimelineItem")
  deleteEndTimelineItem!: (index: number) => void;
  @module.Mutation("setEndTimelineEnd")
  setEndTimelineEnd!: (value: string) => void;

  get canAdd(): boolean {
    return this.endTimeline.items.length < 5;
  }

  editItem(index: number, item: ProjectValuationReportEndTimelineItem) {
    this.editEndTimelineItem({
      index,
      item,
    });
  }

  deleteItem(index: number): void {
    this.deleteEndTimelineItem(index);
  }
}
