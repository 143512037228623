




























import { Component, Prop, Vue } from "vue-property-decorator";
import { ProjectScore } from "@/entity/project-valuation/ProjectValuationScore";
import Answer from "@/components/project-valuation-report/Answer.vue";

@Component({
  components: {
    Answer,
  },
})
export default class StructureGraphs extends Vue {
  @Prop({ type: Object, required: true }) score!: ProjectScore;
}
