



























import { Vue, Component, Prop } from "vue-property-decorator";
import {
  ProjectFinanceDataCell,
  ProjectFinanceDataRow,
} from "@/entity/ProjectFinance";
import { displayValue } from "@/utils/analytics";

@Component
export default class ProjectFinanceTableRow extends Vue {
  @Prop({ required: true, type: Object }) value!: ProjectFinanceDataRow;
  @Prop({ default: false, type: Boolean }) bold!: boolean;
  @Prop({ default: false, type: Boolean }) offset!: boolean;
  @Prop({ default: false, type: Boolean }) italic!: boolean;
  @Prop({ default: false, type: Boolean }) percentage!: boolean;
  @Prop({ required: true, type: Array }) yearColumns!: number[];
  @Prop({ type: Boolean, default: false }) showAdjustmentLabel!: boolean;
  @Prop({ type: String, default: "" }) adjustmentLabel!: string;

  get mapByYear() {
    return this.yearColumns.map((year) => {
      const value = this.value.data.find((item) => {
        return item.year === year;
      });

      return {
        year,
        value: value?.value,
      };
    });
  }

  displayValue(cell: ProjectFinanceDataCell, percentage: boolean): string {
    if (cell.value === undefined) {
      return "";
    }

    return displayValue(cell.value, {
      percentage: percentage,
    });
  }
}
