






































import { Component, Prop, Vue } from "vue-property-decorator";
import Project from "@/entity/Project";
import Loader from "@/components/common/Loader.vue";
import NumberOfBuyersBarChart from "@/components/charts/NumberOfBuyersBarChart.vue";
import PieChart from "@/components/charts/PieChart.vue";
import { ProjectValuationBuyerStats } from "@/entity/project-valuation/ProjectValuationBuyers";
import ProjectValuationBuyerTypeTiles from "@/components/project-valuation-buyers/ProjectValuationBuyerTypeTiles.vue";

@Component({
  components: {
    Loader,
    NumberOfBuyersBarChart,
    PieChart,
    ProjectValuationBuyerTypeTiles,
  },
})
export default class ProjectValuationBuyersStatistics extends Vue {
  @Prop({ required: true, type: Object }) project!: Project;
  @Prop({ required: true, type: Object })
  buyerStats!: ProjectValuationBuyerStats;
  @Prop({ required: true, type: Boolean }) isFetchingBuyerStats!: boolean;
}
