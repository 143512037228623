






import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ValueOrNA extends Vue {
  @Prop() value!: any;

  get displayValue() {
    if (this.value === null || this.value === undefined) {
      return this.$t("na");
    }

    return this.value;
  }
}
