





















import { Component, Vue, Prop } from "vue-property-decorator";
import List from "@/components/project-valuation-report/List.vue";
import Footer from "@/components/project-valuation-report/Footer.vue";
import ProjectFinanceTable from "@/components/project-finance-table/ProjectFinanceTable.vue";
import { ProjectFinanceDataRow } from "@/entity/ProjectFinance";

@Component({
  components: {
    List,
    Footer,
    ProjectFinanceTable,
  },
})
export default class ProjectValuationExternalReportPage8 extends Vue {
  @Prop({ type: Number, required: true }) footerPage!: number;
  @Prop({ type: Array, required: true })
  financeData!: ProjectFinanceDataRow[];

  get whitelistedFinanceData(): ProjectFinanceDataRow[] {
    return this.financeData.filter((item) =>
      this.whitelistedVariableName.includes(item.variable)
    );
  }

  whitelistedVariableName: string[] = [
    "Betriebsertrag",
    "Waren- / Material- / DL-Aufwand",
    "Bruttogewinn",
    "Bruttomarge",
    "Personalaufwand",
    "Raumaufwand",
    "Übriger Betriebsaufwand",
    "EBITDA",
    "EBITDA Marge",
    "Abschreibungen",
    "EBIT",
    "EBIT Marge",
    "Finanzaufwand`",
    "Finanzertrag",
    "Ausserordentlicher Aufwand",
    "Ausserordentlicher Ertrag",
    "EBT",
    "Direkte Steuern",
    "Reingewinn",
  ];

  tableStyling = {
    incomeStatement: {
      boldRows: [
        "Betriebsertrag",
        "Bruttogewinn",
        "EBITDA",
        "EBIT",
        "EBT",
        "Reingewinn",
      ],
      percentageRows: ["Bruttomarge", "EBITDA Marge", "EBIT Marge"],
      italicRows: ["Bruttomarge", "EBITDA Marge", "EBIT Marge"],
    },
  };
}
