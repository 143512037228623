












import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class List extends Vue {
  @Prop({ required: true, type: Array }) items!: string[];
}
