






















import { Component, Prop, Vue } from "vue-property-decorator";
import List from "@/components/project-valuation-report/List.vue";
import { alphabet } from "@/constants";
import { AdjustmentPage } from "@/store/projectValuationReport/types";
import Footer from "@/components/project-valuation-report/Footer.vue";

@Component({
  components: {
    List,
    Footer,
  },
})
export default class ProjectValuationExternalReportPage7 extends Vue {
  @Prop({ type: Array, required: true }) adjustmentsPages!: AdjustmentPage[];
  @Prop({ type: Number, required: true }) footerPage!: number;

  getListStartCharacter(page: AdjustmentPage): number {
    const key = Object.keys(page.adjustments)[0];
    const index = alphabet.indexOf(key);
    if (index !== -1) {
      return index + 1;
    }

    return 0;
  }
}
