












































import { Component, Prop, Vue } from "vue-property-decorator";
import ProjectValuationMarketAnalysisSubTable from "@/components/project-valuation-market/ProjectValuationMarketAnalysisSubTable.vue";
import { DataTableHeader } from "vuetify";
import {
  formatValuationProject,
  formatValuationProjectForExcelExport,
  resizableTable,
} from "@/utils/projectValuation";
import { TableSizes } from "@/services/projectValuation/ProjectValuationMarketService";
import ProjectValuationMarketFilterResult, {
  ProjectValuationMarketMultiples,
} from "@/entity/project-valuation/ProjectValuationMarket";
import DownloadMarketAnalysisCard from "./DownloadMarketAnalysisCard.vue";
import DialogWrapper from "@/components/common/DialogWrapper.vue";

@Component({
  components: {
    ProjectValuationMarketAnalysisSubTable,
    DownloadMarketAnalysisCard,
    DialogWrapper,
  },
})
export default class ProjectValuationMarketAnalysisTable extends Vue {
  @Prop({ type: Array, required: true })
  projects!: ProjectValuationMarketFilterResult[];
  @Prop({ required: true, type: Boolean }) hasEditRights!: boolean;
  @Prop({ type: Array, required: true })
  selectedTransactionMultiples!: string[];
  @Prop({ type: Array, required: true })
  selectedSales!: string[];
  @Prop({ type: Object, required: true }) tableSizes!: TableSizes;
  @Prop({ required: true, type: Array }) salesOptions!: DataTableHeader[];
  @Prop({ required: true, type: Array })
  transactionMultiplesOptions!: DataTableHeader[];

  additionalRowTypes = ["average", "median", "cutting"];

  isDownloadDialogOpen = false;

  removeProject(projectId: number) {
    this.$emit("remove-project", projectId);
  }

  get selectedSalesLocal(): DataTableHeader[] {
    return this.salesOptions.filter((option) =>
      this.selectedSales.includes(option.value)
    );
  }

  get selectedMultiplesLocal(): DataTableHeader[] {
    return this.transactionMultiplesOptions.filter((option) =>
      this.selectedTransactionMultiples.includes(option.value)
    );
  }

  get flatValues(): ProjectValuationMarketMultiples {
    const result: ProjectValuationMarketMultiples = {
      incomeStatementMultiples: {
        turnover: [],
        netProfit: [],
        ebit: [],
        ebt: [],
        ebitda: [],
      },
      transactionMultiples: {
        turnover: [],
        netProfit: [],
        ebit: [],
        ebt: [],
        ebitda: [],
      },
      salesPrice: [],
      fixedPrice: [],
      fixedPriceRatio: [],
      earnOutMax: [],
      earnOutMaxPercentage: [],
      ownerLoan: [],
      ownerLoanPercentage: [],
      duration: [],
      salesPricePublished: [],
      employees: [],
      fullTimeEquivalent: [],
    };

    this.projects.forEach((project) => {
      result.transactionMultiples.turnover.push(
        project.transactionMultiples.turnover ?? 0
      );
      result.transactionMultiples.netProfit.push(
        project.transactionMultiples.netProfit ?? 0
      );
      result.transactionMultiples.ebt.push(
        project.transactionMultiples.ebt ?? 0
      );
      result.transactionMultiples.ebit.push(
        project.transactionMultiples.ebit ?? 0
      );
      result.transactionMultiples.ebitda.push(
        project.transactionMultiples.ebitda ?? 0
      );
      result.incomeStatementMultiples.turnover.push(
        project.incomeStatementMultiples.turnover ?? 0
      );
      result.incomeStatementMultiples.netProfit.push(
        project.incomeStatementMultiples.netProfit ?? 0
      );
      result.incomeStatementMultiples.ebt.push(
        project.incomeStatementMultiples.ebt ?? 0
      );
      result.incomeStatementMultiples.ebit.push(
        project.incomeStatementMultiples.ebit ?? 0
      );
      result.incomeStatementMultiples.ebitda.push(
        project.incomeStatementMultiples.ebitda ?? 0
      );
      result.salesPrice.push(project.projectSales.salesPrice ?? 0);
      result.fixedPrice.push(project.projectSales.fixedPrice ?? 0);
      result.fixedPriceRatio.push(
        project.projectSales.calculationResults?.fixedPriceRatio ?? 0
      );
      result.earnOutMax.push(project.projectSales.earnOutMax ?? 0);
      result.earnOutMaxPercentage.push(
        project.projectSales.calculationResults?.earnOutMaxPercentage ?? 0
      );
      result.ownerLoan.push(project.projectSales.ownerLoan ?? 0);
      result.ownerLoanPercentage.push(
        project.projectSales.calculationResults?.ownerLoanPercentage ?? 0
      );
      result.duration.push(
        project.projectSales.calculationResults?.duration ?? 0
      );
      result.salesPricePublished.push(project.project.salesPricePublished ?? 0);
      result.employees.push(project.project.projectContact.employees ?? 0);
      result.fullTimeEquivalent.push(
        Number(project.project.projectContact.fullTimeEquivalent) ?? 0
      );
    });

    return result;
  }

  get formattedProjects() {
    return this.projects.map(formatValuationProject);
  }

  get formattedProjectsForExcelExport() {
    return this.projects.map(formatValuationProjectForExcelExport);
  }

  get allHeaders() {
    return [
      {
        type: "company",
        headers: this.companyHeaders,
      },
      {
        type: "sales",
        headers: this.selectedSalesLocal,
      },
      {
        type: "multiples",
        headers: this.selectedMultiplesLocal,
      },
    ];
  }

  get companyHeaders(): DataTableHeader[] {
    const columns: DataTableHeader[] = [
      {
        value: "project.projectContact.name",
        text: this.$tc("project", 1),
        class: "text-left",
        cellClass: "text-left",
      },
      {
        value: "project.projectContact.contactBranches",
        text: this.$tc("industry", 1),
        class: "text-left",
        cellClass: "text-left",
      },
      {
        value: "project.projectContact.subBranches",
        text: this.$tc("subIndustry", 1),
        class: "text-left",
        cellClass: "text-left",
      },
      {
        value: "project.projectContact.companyType",
        text: this.$tc("companyType", 1),
        class: "text-left",
        cellClass: "text-left",
      },
      {
        value: "project.projectContact.employees",
        text: this.$tc("employees", 1),
        class: "text-right",
        cellClass: "text-right",
      },
      {
        value: "project.projectContact.fullTimeEquivalent",
        text: this.$tc("fullTimeEquivalent", 1),
        class: "text-right",
        cellClass: "text-right",
      },
      {
        value: "projectSales.baseYear",
        text: this.$tc("projectSalesDataForm.baseYear", 1),
        class: "text-right",
        cellClass: "text-right",
      },
    ];

    if (this.hasEditRights) {
      columns.unshift({
        text: "",
        value: "actions",
        class: "action",
        cellClass: "action",
        width: 40,
      });
    }

    return columns;
  }

  onResizeEnd(name: string, width: number): void {
    const existingTableSize = this.tableSizes;

    let newTableSize: Partial<TableSizes> = {};

    if (existingTableSize) {
      newTableSize = {
        ...existingTableSize,
      };
    }

    this.$emit("table-resized", {
      ...newTableSize,
      [name]: width,
    });
  }

  makeTableResizable() {
    const tableWrappers =
      document.querySelectorAll<HTMLElement>(".resize-layout");

    const initialWidths = this.tableSizes;

    Array.from(tableWrappers).forEach((wrapper) => {
      const name = wrapper.dataset.name as string;
      const initialWidth = initialWidths?.[name as keyof TableSizes];

      resizableTable(
        wrapper,
        wrapper.dataset.name as string,
        (width) => this.onResizeEnd(name, width),
        initialWidth
      );
    });
  }

  closeDownloadDialog() {
    this.isDownloadDialogOpen = false;
  }

  openDownloadDialog() {
    this.isDownloadDialogOpen = true;
  }

  mounted() {
    this.makeTableResizable();
  }
}
