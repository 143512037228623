






















































import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

@Component
export default class ProjectValuationExternalReportPage2 extends Vue {
  @Prop({ type: String, required: true }) companyName!: string;
  @Prop({ type: String, required: true }) companyNumber!: string;
  @Prop({ type: String, required: true }) client!: string;
  @Prop({ type: String, required: true }) valuationDate!: string;
  @Prop({ type: String, required: true }) creationDate!: string;
  @Prop({ type: String, required: true }) consultant!: string;

  get valuationDateReadable(): string {
    if (this.valuationDate.length === 0) return "";
    return moment(this.valuationDate).locale("de").format("D. MMMM YYYY");
  }

  get creationDateReadable(): string {
    if (this.creationDate.length === 0) return "";
    return moment(this.creationDate).locale("de").format("MMMM YYYY");
  }
}
