






















import { Component, Mixins, Prop } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import RulesMixin from "@/mixins/RulesMixin";
import BaseForm from "@/components/form/BaseForm";
import * as XLSX from "xlsx";
import { ProjectValuationMarketMultiples } from "@/entity/project-valuation/ProjectValuationMarket";
import { DataTableHeader } from "vuetify";
import { get } from "lodash";
import { calculateAdditionalRowValues } from "./calculateAdditionalRowValues";

@Component({
  components: {
    FormCard,
  },
})
export default class DownloadMarketAnalysisCard extends Mixins(
  RulesMixin,
  BaseForm
) {
  @Prop({ type: Array, required: true }) formattedProjects!: any[];
  @Prop({ type: Object, required: true })
  flatValues!: ProjectValuationMarketMultiples;
  @Prop({ type: Array, required: true }) headers!: {
    type: string;
    headers: DataTableHeader<any>[];
  }[];

  fileName = "";

  onFileNameKeypress(event: KeyboardEvent): void {
    if (event.key === "Enter" && this.fileName.length > 0) {
      event.preventDefault();
      this.submitForm();
    }
  }

  async submitForm(): Promise<void> {
    try {
      const data: any[] = this.formattedProjects.map(() => ({}));

      this.headers.forEach((parentHeader) => {
        parentHeader.headers.forEach((header) => {
          this.formattedProjects.forEach((project, projectIndex) => {
            const key = header.text;
            const value = get(project, header.value);
            if (key) {
              if (parentHeader.type === "multiples") {
                // some keys have the same name from the types 'multiples' and 'sales'
                // so we make the distinction by adding a space to 'multiplies'
                data[projectIndex][`${key} `] = value;
              } else {
                data[projectIndex][key] = value;
              }
            }
          });
        });
      });

      const averageValueKeys = {
        average: this.$tc("average"),
        median: this.$tc("median"),
        cutting: this.$tc("cuttingMeans"),
      };

      const averages: any[] = Object.values(averageValueKeys).map((item) => ({
        Projekt: item,
      }));

      this.headers.forEach((parentHeader) => {
        parentHeader.headers.forEach((header) => {
          Object.keys(averageValueKeys).map((type, index) => {
            const { finalValue, isNumber, originalValue, isPercentage } =
              calculateAdditionalRowValues(type, header.value, this.flatValues);
            if (
              !Number.isNaN(Number(finalValue)) ||
              finalValue?.includes("'") ||
              finalValue?.includes("%")
            ) {
              const value = isPercentage
                ? Number((originalValue / 100).toFixed(2))
                : isNumber
                ? originalValue
                : finalValue;
              if (parentHeader.type === "multiples") {
                // some keys have the same name from the types 'multiples' and 'sales'
                // so we make the distinction by adding a space to 'multiplies'
                averages[index][`${header.text} `] = value;
              } else {
                averages[index][header.text] = value;
              }
            }
          });
        });
      });

      const finalData = [...data, {}, ...averages];

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(finalData);

      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        this.$tc("projectSales.tabTitle")
      );

      XLSX.writeFile(workbook, `${this.fileName}.xlsx`, { compression: true });

      this.$emit("exported");
    } catch (error) {
      console.error(error);
    }
  }
}
