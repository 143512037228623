

















import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import DatePickerWithInput from "@/components/form/input/DatePickerWithInput.vue";

const module = namespace("projectValuationReport");

@Component({
  components: {
    DatePickerWithInput,
  },
})
export default class ProjectValuationPage15Edit extends Vue {
  @module.Getter("getService")
  service!: string;
  @module.Mutation("setService")
  setService!: (value: string) => void;
}
