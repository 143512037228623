




































































import { Component, Prop, Vue, PropSync } from "vue-property-decorator";
import { ReferenceItem } from "@/services/types";
import RangeSlider from "@/components/form/input/RangeSlider.vue";
import { DEFAULT_MONEY_RANGE_VALUES, CURRENCY } from "@/constants";
import { formatPriceRange } from "@/utils/string";

@Component({
  components: {
    RangeSlider,
  },
})
export default class ProjectValuationBuyersFormFilter extends Vue {
  @Prop({ type: Array, required: true }) regions!: ReferenceItem[];
  @Prop({ type: Array, required: true }) industries!: ReferenceItem[];
  @Prop({ type: Array, required: true }) subIndustries!: ReferenceItem[];
  @PropSync("selectedRegions", { type: Array, required: true })
  selectedRegionsLocal!: ReferenceItem[];
  @PropSync("selectedIndustries", { type: Array, required: true })
  selectedIndustriesLocal!: ReferenceItem[];
  @PropSync("selectedSubIndustries", { type: Array, required: true })
  selectedSubIndustriesLocal!: ReferenceItem[];
  @PropSync("selectedRange", { type: Array, required: true })
  selectedRangeLocal!: [number, number];

  get rangePrice(): string {
    if (this.selectedRangeLocal) {
      return formatPriceRange(
        DEFAULT_MONEY_RANGE_VALUES[this.selectedRangeLocal[0]],
        DEFAULT_MONEY_RANGE_VALUES[this.selectedRangeLocal[1]],
        CURRENCY
      );
    }
    return "";
  }

  reset(name: string) {
    (this.$refs[name] as any).lazySearch = "";
  }
}
