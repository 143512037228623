






import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Quote extends Vue {
  @Prop({ required: true, type: String }) text!: string;
}
