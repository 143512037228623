












import { Component, Mixins, Prop } from "vue-property-decorator";
import TranslatedEnumListsMixin from "@/mixins/TranslatedEnumListsMixin";
import DataTable from "@/components/data-table/DataTable.vue";
import { ITableHeader } from "@/components/data-table/types";
import { getContactFullName } from "@/entity/Contact";
import Link from "@/components/common/Link.vue";
import { ProjectValuationSnapshotBuyers } from "@/entity/project-valuation/ProjectValuationBuyers";

@Component({
  components: {
    DataTable,
    Link,
  },
})
export default class ProjectValuationSummaryBuyersDataTable extends Mixins(
  TranslatedEnumListsMixin
) {
  @Prop({ required: true, type: Array })
  contacts!: ProjectValuationSnapshotBuyers[];
  @Prop({ type: Boolean, required: true }) isLoading!: boolean;
  @Prop({ type: Number, required: true }) totalItems!: number;
  @Prop({ type: String, required: true }) dataTableName!: number;
  @Prop({ type: Function, required: true }) onOptionsChange!: () => void;

  initialTableOptions: any | null = null;
  contactFullName = getContactFullName;

  get headers(): ITableHeader[] {
    return [
      {
        filterType: "text",
        text: this.$tc("name", 1),
        value: "contactFullName",
        width: "200px",
      },
      {
        filterType: "text",
        text: this.$tc("city", 1),
        value: "contactCity",
        width: "150px",
      },
      {
        filterType: "text",
        text: this.$tc("email", 1),
        value: "contactEmail",
        width: "200px",
      },
      {
        sortable: false,
        text: this.$tc("phoneMobile", 1),
        value: "contactMobile",
        width: "200px",
      },
      {
        filterType: "text",
        text: this.$tc("buyerType", 1),
        value: "contactBuyerType",
        sortable: true,
        width: "200px",
      },
    ];
  }
}
