





























type isProjectValueHigherReturnType = "true" | "false" | "same";

import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Graph extends Vue {
  @Prop({ type: Number, required: true }) projectValue!: number;
  @Prop({ type: Number, required: true }) overallValue!: number;
  @Prop({ type: Boolean, default: false }) isPercentage!: boolean;
  @Prop({ type: Number, default: 0 }) toFixed!: number;

  get fixedProjectValue(): number {
    // Get project value with as many decimal points as should be displayed
    return parseFloat(this.projectValue.toFixed(this.toFixed));
  }

  get fixedOverallValue(): number {
    // Get overall value with as many decimal points as should be displayed
    return parseFloat(this.overallValue.toFixed(this.toFixed));
  }

  get fixedZero(): number {
    // Get 0 with as many decimal points as project and overall will be displayed with
    const zero = 0;
    return parseFloat(zero.toFixed(this.toFixed));
  }

  get isProjectValueHigher(): isProjectValueHigherReturnType {
    if (this.fixedProjectValue > this.fixedOverallValue) {
      return "true";
    } else if (this.fixedProjectValue < this.fixedOverallValue) {
      return "false";
    }

    return "same";
  }

  get projectHeight(): number {
    // Displayed project value is really just a zero so the line will have height of 0
    if (this.fixedProjectValue === this.fixedZero) return 0;

    // If project value is higher than overall value then it will have 100% height
    // If project and overall value are the same, but they are not zero then have 100% height
    if (
      this.isProjectValueHigher === "true" ||
      this.isProjectValueHigher === "same"
    ) {
      return 100;
    }

    return (this.fixedProjectValue * 100) / this.fixedOverallValue;
  }

  get overallHeight(): number {
    // Displayed overall value is really just a zero so the line will have height of 0
    if (this.fixedOverallValue === this.fixedZero) return 0;

    // If overall value is higher than project value then it will have 100% height
    // If project and overall value are the same, but they are not zero then have 100% height
    if (
      this.isProjectValueHigher === "false" ||
      this.isProjectValueHigher === "same"
    ) {
      return 100;
    }

    return (this.fixedOverallValue * 100) / this.fixedProjectValue;
  }

  readableValue(value: number): string {
    if (this.isPercentage) return `${value.toFixed(this.toFixed)} %`;
    return value.toFixed(this.toFixed);
  }
}
