






















import { Component, Prop, Vue } from "vue-property-decorator";
import Legend from "@/components/project-valuation-report/Legend.vue";
import { LegendItem } from "@/components/project-valuation-report/interfaces";
import FinalScore from "@/components/project-valuation-report/FinalScore.vue";
import { ProjectScore } from "@/entity/project-valuation/ProjectValuationScore";
import RiskGraphs from "@/components/project-valuation-report/RiskGraphs.vue";
import FinanceGraphs from "@/components/project-valuation-report/FinanceGraphs.vue";
import StructureGraphs from "@/components/project-valuation-report/StructureGraphs.vue";
import Footer from "@/components/project-valuation-report/Footer.vue";

@Component({
  components: {
    Footer,
    Legend,
    FinalScore,
    RiskGraphs,
    FinanceGraphs,
    StructureGraphs,
  },
})
export default class ProjectValuationExternalReportPage16 extends Vue {
  @Prop({ type: String, required: true }) projectName!: string;
  @Prop({ type: Object, required: true }) score!: ProjectScore;
  @Prop({ type: Number, required: true }) footerPage!: number;

  get legendItems(): LegendItem[] {
    return [
      {
        name: this.projectName,
        color: "#224183",
      },
      {
        name: "Peergroup",
        color: "#3bdaa1",
      },
    ];
  }
}
