
















































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Project from "@/entity/Project";
import { min, max } from "lodash";
import { ProjectFinanceDataRow } from "@/entity/ProjectFinance";
import { ProjectTransactionMultiples } from "@/entity/ProjectSales";
import { formatPrice } from "@/utils/string";
import { calculateAverage, pluckAndJoin } from "@/utils/array";
import MissingDataText from "@/components/common/MissingDataText.vue";
import PriceValuationInput from "@/components/project-valuation-market/PriceValuationInput.vue";
import InlineEditDialog from "@/components/common/InlineEditDialog.vue";
import {
  formatMarketPriceValuation,
  marketPriceValuationMultipleTranslate,
  MarketPriceValuationResult,
  yearValueMap,
} from "@/utils/projectValuation";
import { ValuationInputs } from "@/services/projectValuation/ProjectValuationMarketService";
import ProjectValuationMarketFilterResult from "@/entity/project-valuation/ProjectValuationMarket";

interface DefaultMultiples {
  min: number;
  max: number;
}

@Component({
  components: {
    MissingDataText,
    PriceValuationInput,
    InlineEditDialog,
  },
})
export default class ProjectValuationMarketValuationTable extends Vue {
  @Prop({ type: Object, required: true }) project!: Project;
  @Prop({ type: Array, required: true })
  projects!: ProjectValuationMarketFilterResult[];
  @Prop({ required: true, type: Boolean }) hasEditRights!: boolean;
  @Prop({ type: String }) selectedMultiple!: string | null;
  @Prop({ type: Number }) baseYear!: number | null;
  @Prop({ type: Array }) projectFinance!: ProjectFinanceDataRow[];
  @Prop({ type: Array, required: true }) excludedResults!: string[];
  @Prop({ type: Object }) valuationInputs!: ValuationInputs;

  minMultipleValue = 0;
  maxMultipleValue = 0;
  averageMin = 0;
  averageMax = 0;
  results: MarketPriceValuationResult[] = [];

  formatPrice = formatPrice;

  get lastRowColSpan(): number {
    return this.hasEditRights ? 4 : 3;
  }

  get inputData(): ValuationInputs {
    return {
      minMultipleValue: this.minMultipleValue,
      maxMultipleValue: this.maxMultipleValue,
      averageMin: this.averageMin,
      averageMax: this.averageMax,
    };
  }

  get multipleTranslate(): string | null {
    if (this.selectedMultiple === null) return null;

    return marketPriceValuationMultipleTranslate(this.selectedMultiple);
  }

  @Watch("inputData")
  onInputDataChanged(data: ValuationInputs): void {
    this.$emit("valuation-inputs-changed", data);
  }

  get showTable(): boolean {
    return this.selectedMultiple !== null && this.baseYear !== null;
  }

  get selectedMultipleTranslation(): string {
    if (this.selectedMultiple === null) return "";
    return this.$t(this.selectedMultiple).toString();
  }

  get branchNames(): string {
    return pluckAndJoin(
      this.project.projectContact?.contactBranches ?? [],
      "name"
    );
  }

  get subBranchNames(): string {
    return pluckAndJoin(this.project.projectContact?.subBranches ?? [], "name");
  }

  get yearValueMap(): { [key: number]: number } {
    if (this.multipleTranslate === null) return {};

    return yearValueMap(this.projectFinance, this.multipleTranslate);
  }

  getResults(): void {
    if (!this.baseYear) {
      this.results = [];
      return;
    }

    this.results = formatMarketPriceValuation(
      this.yearValueMap,
      this.baseYear,
      this.selectedMultipleTranslation,
      this.minMultipleValue,
      this.maxMultipleValue
    );
  }

  excludeResult(key: string): void {
    const resultKeysFromStorage = this.excludedResults;

    let excluded: string[] = [key];

    if (Array.isArray(resultKeysFromStorage)) {
      excluded = excluded.concat(resultKeysFromStorage);
    }

    this.excludedResultsChanged(excluded);
  }

  clearExcludedResults(): void {
    this.excludedResultsChanged([]);
  }

  excludedResultsChanged(data: string[]) {
    this.$emit("excluded-results-changed", data);
  }

  get resultsToShow(): MarketPriceValuationResult[] {
    const resultKeysFromStorage = this.excludedResults;

    if (!resultKeysFromStorage) {
      return this.results;
    }

    return this.results.filter(
      (result) => !resultKeysFromStorage.includes(result.key)
    );
  }

  get defaultAverages(): DefaultMultiples {
    return {
      min: Math.round(
        calculateAverage(this.results.map((parameter) => parameter.from))
      ),
      max: Math.round(
        calculateAverage(this.results.map((parameter) => parameter.to))
      ),
    };
  }

  get defaultMultiples(): DefaultMultiples {
    const valuesOfSelectedMultiple = this.projects.map(
      (project) =>
        project.transactionMultiples[
          this.selectedMultiple as keyof ProjectTransactionMultiples
        ]
    );

    return {
      min: parseFloat(min(valuesOfSelectedMultiple)?.toFixed(1) ?? "0"),
      max: parseFloat(max(valuesOfSelectedMultiple)?.toFixed(1) ?? "0"),
    };
  }

  removeResult(result: MarketPriceValuationResult, index: number) {
    this.results.splice(index, 1);
    this.resetAverages();
    this.excludeResult(result.key);
  }

  get isMinMultipleChanged(): boolean {
    return this.minMultipleValue !== this.defaultMultiples.min;
  }

  get isMaxMultipleChanged(): boolean {
    return this.maxMultipleValue !== this.defaultMultiples.max;
  }

  get isAverageMinChanged(): boolean {
    return this.averageMin !== this.defaultAverages.min;
  }

  get isAverageMaxChanged(): boolean {
    return this.averageMax !== this.defaultAverages.max;
  }

  resetAverages() {
    this.averageMin = this.defaultAverages.min;
    this.averageMax = this.defaultAverages.max;
  }

  @Watch("selectedMultiple")
  onSelectedMultipleChanged(): void {
    this.getResults();
    this.clearExcludedResults();
  }

  @Watch("baseYear")
  onBaseYearChanged(): void {
    this.getResults();
    this.clearExcludedResults();
    this.resetAverages();
  }

  onMinMultipleChanged(): void {
    this.getResults();
    this.averageMin = this.defaultAverages.min;
  }

  resetMinMultiple() {
    this.minMultipleValue = this.defaultMultiples.min;
    this.onMinMultipleChanged();
  }

  onMaxMultipleChanged(): void {
    this.getResults();
    this.averageMax = this.defaultAverages.max;
  }

  resetMaxMultiple() {
    this.maxMultipleValue = this.defaultMultiples.max;
    this.onMaxMultipleChanged();
  }

  @Watch("defaultMultiples")
  onDefaultMultiplesChange(value: DefaultMultiples): void {
    this.minMultipleValue = value.min;
    this.onMinMultipleChanged();
    this.maxMultipleValue = value.max;
    this.onMaxMultipleChanged();
    this.clearExcludedResults();
  }

  @Watch("valuationInputs", { deep: true, immediate: true })
  onValuationInputsChange(value: ValuationInputs): void {
    this.maxMultipleValue = value.maxMultipleValue;
    this.minMultipleValue = value.minMultipleValue;
    this.averageMax = value.averageMax;
    this.averageMin = value.averageMin;
  }

  created() {
    this.getResults();
  }
}
