



























import { Component, Prop, Vue } from "vue-property-decorator";
import Legend from "@/components/project-valuation-report/Legend.vue";
import { LegendItem } from "@/components/project-valuation-report/interfaces";
import { maxBy } from "lodash";
import { ProjectValuationBuyerChart } from "@/entity/project-valuation/ProjectValuationBuyers";

@Component({
  components: {
    Legend,
  },
})
export default class NumberOfBuyersGraph extends Vue {
  @Prop({ type: Array, required: true }) value!: ProjectValuationBuyerChart[];

  get maxValue(): number {
    return maxBy(this.value, "count")?.count ?? 0;
  }

  getPadding(value: number): number {
    return 100 - (value / this.maxValue) * 100;
  }

  get legendItems(): LegendItem[] {
    return [
      {
        name: "Branche",
        color: "#224183",
      },
      {
        name: "Branche und Region",
        color: "#91a0c1",
      },
      {
        name: "Branche, Region Grösse",
        color: "#444444",
      },
    ];
  }
}
