
























import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class SmallSegment extends Vue {
  @Prop({ required: true, type: String }) title!: string;
  @Prop({ required: true, type: String }) value!: string;
  @Prop({ required: true, type: String }) image!: string;
}
