

















import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import DatePickerWithInput from "@/components/form/input/DatePickerWithInput.vue";

const module = namespace("projectValuationReport");

@Component({
  components: {
    DatePickerWithInput,
  },
})
export default class ProjectValuationPage12Edit extends Vue {
  @module.Getter("getBuyerDatabaseQuote") buyerDatabaseQuote!: string;
  @module.Mutation("setBuyerDatabaseQuote") setBuyerDatabaseQuote!: (
    value: string
  ) => void;
}
