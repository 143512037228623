











































import { Component, Vue, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import DatePickerWithInput from "@/components/form/input/DatePickerWithInput.vue";
import { EditAdjustmentPayload } from "@/store/projectValuationReport/types";
import PeerGroupItemEdit from "@/components/project-valuation-report/PeerGroupItemEdit.vue";

const module = namespace("projectValuationReport");

// Needed for the quick fix :) check the commit
const alphabet = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
const LAST_ELEMENT_INDEX_TO_DISPLAY_BY_DEFAULT = 7;

@Component({
  components: {
    PeerGroupItemEdit,
    DatePickerWithInput,
  },
})
export default class ProjectValuationPage7Edit extends Vue {
  @Prop({ type: Number, required: true }) initialLength!: number;

  @module.Getter("getAdjustments")
  adjustments!: { [key: string]: string };
  @module.Mutation("editAdjustment")
  editAdjustment!: (payload: EditAdjustmentPayload) => void;
  @module.Mutation("initializeAdjustments")
  initializeAdjustments!: (length: number) => void;
  @module.Mutation("deleteAdjustment")
  deleteAdjustment!: (key: string) => void;

  get adjustmentKeys(): string[] {
    // return Object.keys(this.adjustments);
    const keys = alphabet.slice(0, LAST_ELEMENT_INDEX_TO_DISPLAY_BY_DEFAULT);

    alphabet.slice(LAST_ELEMENT_INDEX_TO_DISPLAY_BY_DEFAULT).forEach((item) => {
      if (this.adjustments[item] !== undefined) {
        keys.push(item);
      }
    });

    return keys;
  }

  editItem(key: string, value: string) {
    this.editAdjustment({
      key,
      value,
    });
  }

  addPageBreak(adjustmentKey: string): void {
    const input = document.getElementById(
      `${adjustmentKey}_adjustment-text-area`
    ) as HTMLTextAreaElement;
    const cursorPosition = input.selectionStart;
    const text = this.adjustments[adjustmentKey];
    const textBefore = text.substring(0, cursorPosition);
    const textAfter = text.substring(cursorPosition, text.length);
    const newText = `${textBefore}\\p${textAfter}`;
    this.editItem(adjustmentKey, newText);
  }

  addKey() {
    const lastOriginalKey = this.adjustmentKeys[this.adjustmentKeys.length - 1];
    const lastOriginalKeyIndexInAlphabet = alphabet.findIndex(
      (item) => item === lastOriginalKey
    );
    const keyToAdd = alphabet[lastOriginalKeyIndexInAlphabet + 1];
    this.editAdjustment({ key: keyToAdd, value: "" });
  }

  removeKey() {
    const lastOriginalKey = this.adjustmentKeys[this.adjustmentKeys.length - 1];
    this.deleteAdjustment(lastOriginalKey);
  }

  get disableRemoveKey() {
    return (
      this.adjustmentKeys.length <= LAST_ELEMENT_INDEX_TO_DISPLAY_BY_DEFAULT
    );
  }

  created() {
    if (this.adjustmentKeys.length === 0) {
      this.initializeAdjustments(this.initialLength);
    }
  }
}
