
















import { Component, Prop, Vue } from "vue-property-decorator";
import Footer from "@/components/project-valuation-report/Footer.vue";
import ProjectFinanceTable from "@/components/project-finance-table/ProjectFinanceTable.vue";
import { ProjectFinanceDataRow } from "@/entity/ProjectFinance";

@Component({
  components: {
    Footer,
    ProjectFinanceTable,
  },
})
export default class ProjectValuationExternalReportPage6 extends Vue {
  @Prop({ type: Number, required: true }) footerPage!: number;
  @Prop({ type: Array, required: true }) adjustments!: ProjectFinanceDataRow[];

  boldRows = ["adjusted EBITDA"];
}
