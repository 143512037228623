var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',{class:{
    'bold-row': _vm.bold,
    'fat-border': _vm.bold,
    'green-border': _vm.bold,
    'upper-border': _vm.bold,
    'offset-row': _vm.offset,
    'italic-row': _vm.italic,
  }},[_c('td',{staticClass:"name-cell",domProps:{"textContent":_vm._s(_vm.value.variable)}}),_vm._l((_vm.mapByYear),function(cell){return _c('td',{key:cell.year},[_c('div',{staticClass:"cell-content text-right"},[_vm._v(" "+_vm._s(_vm.displayValue(cell, _vm.percentage))+" ")])])}),(_vm.showAdjustmentLabel)?_c('td',[_c('div',{staticClass:"cell-content adjustment-label"},[_vm._v(" "+_vm._s(_vm.adjustmentLabel)+" ")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }