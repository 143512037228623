import Axios from "axios";
import {
  ProjectScore,
  ProjectScoreAnswerInput,
} from "@/entity/project-valuation/ProjectValuationScore";

export default class ProjectValuationScoreService {
  static async get(projectId: number): Promise<ProjectScore> {
    const { data } = await Axios.get(`/api/v2/valuation/score/${projectId}`);
    return data;
  }

  static async saveAnswers(
    projectId: number,
    answers: ProjectScoreAnswerInput
  ): Promise<ProjectScore> {
    const { data } = await Axios.post(
      `/api/v2/valuation/score/${projectId}/answers`,
      answers
    );
    return data;
  }
}
